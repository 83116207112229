<template>
    <div>
        <Header></Header>
        <div class="container">
            <h4>Detalle Ciclo: {{ this.labelOriginal }}</h4>
            <div class="col-sm-12">
                <label class="form-label">Nombre:</label>
                <input v-model="form.label" class="form-control" placeholder="Nombre">
            </div>
            <div class="col-sm-12">
                <label class="form-label">F. Inicio:</label>
                <input v-model="form.fecha_ini" type="date" class="form-control" placeholder="F. Inicio">
            </div>
            <div class="col-sm-12">
                <label class="form-label">F. Fin:</label>
                <input v-model="form.fecha_fin" type="date" class="form-control" placeholder="F. Fin">
            </div>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
            <div class="text-center col-xs-12 listButton">
                <button @click="saveCiclo" class="btn btn-success">Guardar</button> 
                <router-link :to="{name:'AdminCiclos', params:{codigo: this.form.codigoUni}}" class="btn btn-primary btnAtras" tag="button">
                    Atras
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    computed:{
        nuevoCiclo(){
            return (this.$route.params.ciclo==='new');
        }
    },
    data(){
        return{
            form:{
                label: '',
                fecha_ini: '',
                fecha_fin: '',
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                action: (this.$route.params.ciclo==='new' ? 'newAdminCiclo' : 'editAdminCiclo')
            },
            labelOriginal: '',
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    mounted(){
        if( !this.nuevoCiclo ){
            this.$store.dispatch('ajaxAdminCursos', {
                action: 'getCicloDetalle',
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo
            })
            .then( data=> {
                this.form.label = data.data.label;
                this.form.fecha_ini = data.data.fecha_inicio;
                this.form.fecha_fin = data.data.fecha_fin;
                this.labelOriginal = data.data.label;
            });
        }else{
            this.labelOriginal='Nuevo Ciclo';
        }
    },
    methods:{
        saveCiclo(){
            this.$store.dispatch('ajaxAdminCursos', this.form)
            .then( data=> {
                this.$helpers.executeMessage( this, data );
            });
        }
    }
}
</script>
<style scoped>
    .listButton{
        margin-top: 10px;
    }
    .btnAtras{
       margin-left: 4px;
    }
</style>