<template>
    <div>
        <Header></Header>
        <div class="text-center">
            <router-link :to="{name:'AdminCursosDetalle', params:{codigo: codigo, ciclo: ciclo, curso:'new'}}" class="btn btn-sm btn-primary" tag="button" id="btnNuevoCurso">
                Nuevo Curso
            </router-link>
        </div>
        
        <div class="container">
            <h5>
                <router-link to="/admin/universidades" class="link-dark">Universidades</router-link> / 
                <router-link :to="{name:'AdminCiclos', params:{codigo: codigo}}" class="link-dark">{{ codigo }}</router-link> /
                <router-link :to="{name:'AdminCursos', params:{codigo: codigo, ciclo: ciclo}}" class="link-dark">
                    {{ this.adminLabelCiclo }}
                </router-link>
            </h5>
            <table class="table table-bordered">
                <thead>
                    <th>Nombre</th>
                    <th>Horarios</th>
                    <th>Temas</th>
                    <th>Clases</th>
                    <th>Detalle/Editar</th>
                </thead>
                <tbody>
                    <tr v-for="data in this.adminCursos" :key="data.id_ciclo">
                        <td>{{ data.label }}</td>
                        <td>
                            <div v-for="(item,index) in data.horarios" :key="index">
                                {{ item.dia+" / "+item.hini+" - "+item.hfin }}
                            </div>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminTemas', params:{codigo: codigo, ciclo: ciclo, curso:data.id_curso}}" class="btn btn-sm btn-primary" tag="button">
                                <i class="bi bi-bookmark-check"></i>
                            </router-link>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminClases', params:{codigo: codigo, ciclo: ciclo, curso:data.id_curso}}" class="btn btn-sm btn-info" tag="button">
                                <i class="bi bi-journal-check"></i>
                            </router-link>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminCursosDetalle', params:{codigo: codigo, ciclo: ciclo, curso:data.id_curso}}" class="btn btn-sm btn-success" tag="button">
                                <i class="bi bi-pencil"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            codigo: this.$route.params.codigo,
            ciclo: this.$route.params.ciclo,
            adminCursos: [],
            adminLabelCiclo: ''
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.codigo,
            codigoCiclo: this.ciclo,
            action: 'getListaCursos'
        })
        .then( data=>{
            this.adminCursos = data.data;
            this.adminLabelCiclo = data.label_1;
        });
    }
}
</script>
<style scoped>
     #btnNuevoCurso{
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>