<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <router-link to="/dashboard" class="navbar-brand text-center">
            <img src="@/assets/logo.png" alt="" width="30" height="24">
        </router-link> 
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <router-link v-if="showTabs" to="/admin/universidades" class="nav-item" tag="li">
                    <a class="nav-link active" aria-current="page" href="#">Gestión de Cursos</a>
                </router-link>
                <router-link v-if="showTabs" to="/admin/usuarios" class="nav-item" tag="li">
                    <a class="nav-link active" aria-current="page" href="#">Usuarios</a>
                </router-link>
                <li class="nav-item">
                    <a @click="closeSession" class="nav-link active" aria-current="page" href="#">
                        <strong>Cerrar Sesión <i class="bi bi-box-arrow-right"></i></strong>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    </nav>
</template>

<script>
export default {
    name: "Header",
    props: {
        esAdmin: Boolean
    },
    computed:{
        showTabs(){
            return localStorage.show_tabs=='1';
        }
    },
    methods: {
        closeSession() {
            //
            this.$store.dispatch('ajaxLogout', {})
            .then( data=>{
                console.log(data);
                location.href = '/academia/';
            });
        }
    }
}
</script>

<style scoped>
</style>