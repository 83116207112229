<template>
    <div>
        <Header></Header>
        <div class="container">
            <h5>
                <router-link to="/admin/universidades" class="link-dark">Universidades</router-link> / 
                <router-link :to="{name:'AdminCiclos', params:{codigo: codigo}}" class="link-dark">{{ codigo }}</router-link> /
                <router-link :to="{name:'AdminCursos', params:{codigo: codigo, ciclo: ciclo}}" class="link-dark">
                    {{ this.adminLabelCiclo }}
                </router-link> / 
                <router-link :to="{name:'AdminTemas', params:{codigo: codigo, ciclo: ciclo, curso:curso}}" class="link-dark">
                    {{ this.adminLabelCurso }}
                </router-link> / 
                <a class="link-dark" href="#">Clases</a>
            </h5>
            <h5>Nueva Clase:</h5>
            <div class="col-sm-12">
                <label class="form-label">Tema:</label>
                <select v-model="formClase.codigoTema" class="form-control">
                    <option v-for="item in this.adminTemas" :key="item.id_tema" :value="item.id_tema">
                        {{ item.label }}
                    </option>
                </select>
            </div>
            <div class="col-sm-12">
                <label class="form-label">Horario:</label>
                <select v-model="formClase.codigoHorario" class="form-control">
                    <option v-for="item in this.adminHorarios" :key="item.id_horario" :value="item.id_horario">
                        {{ listaSemana[item.dia]+" ("+item.hini+" - "+item.hfin+")" }}
                    </option>
                </select>
            </div>
            <div class="col-sm-12">
                <label class="form-label">Link Reunión:</label>
                <input v-model="formClase.linkReunion" class="form-control" placeholder="Link Reunión">
            </div>
            <div class="col-sm-12">
                <label class="form-label">Fecha Reunión:</label>
                <input v-model="formClase.fechaReunion" class="form-control" type="date">
            </div>
            <div class="col-sm-12">
                <label class="form-label">Detalle Reunión:</label>
                <textarea v-model="formClase.detalleReunion" class="form-control" placeholder="Detalle Reunión"></textarea>
            </div>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
            <div class="text-center col-xs-12 listButton">
                <button @click="saveNuevaClase" class="btn btn-sm btn-success">Guardar</button>
            </div>
            <div class="cont-table">
                <h5>Clases Creadas:</h5>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Tema</th>
                            <th>Fecha</th>
                            <th>Horario</th>
                            <th>Link</th>
                            <th>Eventos</th>
                            <th>T. Grabacion</th>
                            <th>Detalle</th>
                            <th>Estado</th>
                            <th>Desactivar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.adminClases" :key="item.id_clase">
                            <td>{{ item.tema }}</td>
                            <td>{{ item.fecha }}</td>
                            <td>{{ listaSemana[item.dia]+" "+item.horario }}</td>
                            <td class="text-center">
                                <a target="_blank" :href="item.link_reunion" class="btn btn-sm btn-info">
                                    <i class="bi bi-arrow-up-right-square"></i>
                                </a>
                            </td>
                            <td class="text-center">
                                <router-link :to="{name:'AdminEventos', params:{codigo: codigo, ciclo: ciclo, curso:curso, clase:item.id_clase}}" class="btn btn-sm btn-warning">
                                    <i class="bi bi-bullseye"></i>
                                </router-link>
                            </td>
                            <td>{{ item.video }}</td>
                            <td>{{ item.detalle }}</td>
                            <td>{{ item.estado=='1' ? 'Activo' : 'Desactivado' }}</td>
                            <td class="text-center">
                                <button @click="disableClase(item.id_clase)" class="btn btn-sm btn-danger">
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    computed:{
        codigo(){
            return this.$route.params.codigo;
        },
        ciclo(){
            return this.$route.params.ciclo;
        },
        curso(){
            return this.$route.params.curso;
        }
    },
    data(){
        return{
            formClase: {
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoTema: '',
                codigoHorario: '',
                linkReunion: '',
                fechaReunion: '',
                detalleReunion: '',
                action: 'newAdminClase'
            },
            adminLabelCiclo: '',
            adminLabelCurso: '',
            adminTemas: [],
            adminHorarios: [],
            adminClases: [],
            listaSemana: this.$helpers.getListaSemana(),
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    mounted(){
        //info de direccion
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.codigo,
            codigoCiclo: this.ciclo,
            codigoCurso: this.curso,
            returnHorarios: '1',
            returnClases: '1',
            action: 'getListaTemas'
        })
        .then(data=>{
            this.adminTemas = data.data;
            this.adminHorarios = data.horarios;
            this.adminClases = data.clases;
            this.adminLabelCiclo = data.label_1;
            this.adminLabelCurso = data.label_2;
        });
    },
    methods:{
        saveNuevaClase(){
            this.$store.dispatch('ajaxAdminCursos', this.formClase)
            .then( data=> {
                this.$helpers.executeMessage( this, data );
                setTimeout(function(){ location.reload(); }, 2000);
            });
        },
        disableClase(id_clase){
            if( !confirm("Se procederá a desactivar la clase, desea continuar?") )
                return false;
            this.$store.dispatch('ajaxAdminCursos', {
                action: 'removeClase',
                codigoUni: this.codigo,
                codigoCiclo: this.ciclo,
                codigoCurso: this.curso,
                codigoClase: id_clase
            })
            .then( data=> {
                this.$helpers.executeMessage( this, data );
                this.adminClases = data.data;
            });
        }
    }
}
</script>
<style scoped>
    .listButton{
        margin-top: 10px;
    }
    textarea{
        resize: none;
    }
    .cont-table{
        margin-top: 10px;
    }
</style>