<template>
    <div>
        <Header></Header>
        <div v-if="!showResult" class="container">
            <h4>{{ labelSimulacro }} - Tiempo Restante: <label class="bold">{{ tiempoRestante!='END_TIME' ? tiempoRestante : '00:00:00' }}</label></h4>
        </div>
        <div v-if="loaded && tiempoRestante!='END_TIME'" class="col-sm-12 container">
            <div class="text-center btnList">
                <button @click="finSimulacro" class="btn btn-sm btn-success">Finalizar</button>
            </div>
            <div>
                <h5>Pregunta {{preguntaActiva}}:</h5>
                <div class="text-center">
                    <img class="image" :src="$store.getters.urlApiImagenes+dataActiva.imagen"/>
                </div>
                <div class="text-center">
                    <div v-for="(opcion, index) in listaOpciones" :key="index" class="form-check form-check-inline">
                        <input v-model="listaPreguntas[preguntaActiva].marcado" :value="opcion" class="form-check-input" name="opcionName" type="radio">
                        <label class="form-check-label">
                            {{ opcion }}&nbsp;
                        </label>
                    </div>
                </div>
                <div class="text-center btnList">
                    <button v-if="preguntaActiva>1" @click="prevPreg" class="btn btn-sm btn-primary">
                        <i class="bi bi-arrow-left-circle"></i>
                    </button>&nbsp;&nbsp;
                    <button @click="marcaOpcion" class="btn btn-sm btn-success">
                        <i class="bi bi-sd-card"></i>    
                    </button>&nbsp;&nbsp;
                    <button v-if="preguntaActiva<numeroPreguntas" @click="nextPreg" class="btn btn-sm btn-primary">
                        <i class="bi bi-arrow-right-circle"></i>
                    </button>
                </div>
            </div>
            
        </div>
        <div v-if="isFin && tiempoRestante!='END_TIME'" class="col-sm-12 container">
            <div class="text-center btnList">
                <button @click="cancelaFin" class="btn btn-sm btn-danger">Cancelar</button>
            </div>
            <h4>Resumen de Marcado:</h4>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Marcadas</th>
                        <th>Sin Marcar</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ numeroMarca }}</td>
                        <td>{{ numeroNoMarca }}</td>
                        <td>{{ numeroPreguntas }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center btnList">
                <button @click="completaFin" class="btn btn-sm btn-success">Confirmar</button>
            </div>
        </div>
        <div v-if="showResult && tiempoRestante=='END_TIME'" class="col-sm-12 container">
            <h4>Resumen Resultados:</h4>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Correctas</th>
                        <th>Incorrectas</th>
                        <th>No Marcadas</th>
                        <th>Puntaje Base</th>
                        <th>Puntaje Obtenido</th>
                        <th>Puntaje Ponderado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ infoResultados.correctas }}</td>
                        <td>{{ infoResultados.incorrectas }}</td>
                        <td>{{ infoResultados.no_marcadas }}</td>
                        <td>{{ infoResultados.puntaje_base }}</td>
                        <td class="infoNota">{{ infoResultados.puntaje }}</td>
                        <td class="infoNota">{{ infoResultados.puntaje_ponderado }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="tiempoRestante=='END_TIME' && !isTimeEnd" class="container">
            <h5>
                Se terminó el tiempo del simulacro, puede revisar sus resultados en la pantalla principal.
            </h5>
        </div>

        <div class="container text-center divAlert">
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    name: "Simulacros",
    components: {
       Header
    },
    data(){
        return {
            listaPreguntas: {},
            listaOpciones: [],
            formSimulacro:{
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoSimulacro: this.$route.params.simulacro,
                action: 'getInfoSimulacro'
            },
            labelSimulacro: '',
            infoResultados:{},
            preguntaActiva: 1,
            numeroPreguntas: 0,
            loaded: false,
            isFin: false,
            showResult: false,
            isTimeEnd: false,
            hora_ini: 0,
            hora_fin: 0,
            hora_act: 0,
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: '',
            numeroMarca: 0,
            numeroNoMarca: 0
        }
    },
    mounted(){
        this.$store.dispatch('ajaxSimulacros', this.formSimulacro)
        .then(data=>{
            if( data.is_finished=="1" ){
                this.showResult = true;
                this.isTimeEnd = true;
                this.infoResultados = data.data;
            }else{
                this.labelSimulacro = data.data.label;
                this.listaPreguntas = data.data.preguntas;
                this.listaOpciones = data.data.opciones;
                this.numeroPreguntas = Object.keys(this.listaPreguntas).length;
                this.loaded = true;
                //parseando tiempo
                let tini = data.data.hora_ini.split(':');
                let tfin = data.data.hora_fin.split(':');
                let tact = data.data.hora.split(':');
                this.hora_ini = parseInt(tini[0])*3600 + parseInt(tini[1])*60 + parseInt(tini[2]);
                this.hora_fin = parseInt(tfin[0])*3600 + parseInt(tfin[1])*60 + parseInt(tfin[2]);
                this.hora_act = parseInt(tact[0])*3600 + parseInt(tact[1])*60 + parseInt(tact[2]);
            }
        });

        setInterval(()=>{
            this.hora_act += 1;
        }, 1000);

        let that=this;
        document.addEventListener('keydown', function(e){
            if( e.keyCode==37 )
                that.prevPreg();
            else if( e.keyCode==39 )
                that.nextPreg();
        });
    },
    computed:{
        dataActiva(){
            return this.listaPreguntas[this.preguntaActiva];
        },
        tiempoRestante(){
            let tmpDif=this.hora_fin-this.hora_act;
            let resTim='';
            if( tmpDif>=0 ){
                let h=Math.floor(tmpDif/3600);
                let m=Math.floor((tmpDif-3600*h)/60);
                let s=tmpDif-3600*h-60*m;
                h=(h<10 ? '0'+h : h);
                m=(m<10 ? '0'+m : m);
                s=(s<10 ? '0'+s : s);
                resTim=h+':'+m+':'+s;
            }
            if( tmpDif<=0 )
                return 'END_TIME';
            return resTim;
        },
        getPreguntaActiva(){
            return this.preguntaActiva;
        }
    },
    methods:{
        nextPreg(){
            if( this.preguntaActiva<this.numeroPreguntas )
                this.preguntaActiva+=1;
        },
        prevPreg(){
            if( this.preguntaActiva>1 )
                this.preguntaActiva-=1;
        },
        marcaOpcion(){
            this.$store.dispatch('ajaxSimulacros', {
                action: 'savePreguntaSimulacro',
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoSimulacro: this.$route.params.simulacro,
                codigoPregunta: this.dataActiva.id_pregunta,
                codigoOrden: this.dataActiva.orden,
                respuesta: this.dataActiva.marcado
            })
            .then(data=>{
                this.$helpers.executeMessage( this, data );
            });
        },
        finSimulacro(){
            this.isFin = true;
            this.loaded = false;
            this.numeroMarca = 0;
            this.numeroNoMarca = 0;
            for(  let index in this.listaPreguntas ){
                let opc=this.listaPreguntas[index].marcado;
                if( opc!=null && opc.trim()!='' )
                    this.numeroMarca++;
                else
                    this.numeroNoMarca++;
            }
        },
        cancelaFin(){
            this.isFin = false;
            this.loaded = true;
        },
        completaFin(){
            if( !confirm("Se procedera a Enviar, desea continuar?") )
                return false;
            this.$store.dispatch('ajaxSimulacros', {
                action: 'saveFinSimulacro',
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoSimulacro: this.$route.params.simulacro
            })
            .then(data=>{
                if( data.result=="1" ){
                    this.showResult = true;
                    this.isFin = false;
                    this.loaded = false;
                    this.infoResultados = data.data;
                }else{
                    this.$helpers.executeMessage( this, data );
                }
            });
        }
    }
}
</script>

<style scoped>
    .btnList{
        margin-top: 20px;
    }
    .divAlert{
        margin-top: 20px;
    }
    .infoNota{
        background-color: aquamarine;
    }
    .bold{
        font-weight: bold;
    }
    .image{
        max-width: 750px;
    }
</style>