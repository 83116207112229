import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import GestionUniversidades from '../views/GestionUniversidades.vue'
import GestionCiclos from '../views/GestionCiclos.vue'
import GestionCursos from '../views/GestionCursos.vue'
import GestionTemas from '../views/GestionTemas.vue'
import GestionClases from '../views/GestionClases.vue'
import GestionUsuarios from '../views/GestionUsuarios.vue'
import AdminUsuariosDetalle from '../views/AdminUsuariosDetalle.vue'
import AdminCiclosDetalle from '../views/AdminCiclosDetalle.vue'
import AdminCursosDetalle from '../views/AdminCursosDetalle.vue'
import AdminTemasDetalle from '../views/AdminTemasDetalle.vue'
import AdminEventos from '../views/AdminEventos.vue'
import CursosTemas from '../views/CursosTemas.vue'
import CursosArchivos from '../views/CursosArchivos.vue'
import CursosNotas from '../views/CursosNotas.vue'
import CursosSimulacros from '../views/CursosSimulacros.vue'
import GestionSimulacros from '../views/GestionSimulacros.vue'
import AdminSimulacrosDetalle from '../views/AdminSimulacrosDetalle.vue'
import AdminNuevoSimulacro from '../views/AdminNuevoSimulacro.vue'
import AdminReporteUsuarios from '../views/AdminReporteUsuarios.vue'
import SystemInfo from '../views/SystemInfo.vue'
import SimulacroDetalle from '../views/SimulacroDetalle.vue'
import AdminCopiaVideo from '../views/AdminCopiaVideo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/admin/universidades',
    name: 'AdminUniversidades',
    component: GestionUniversidades
  },
  {
    path: '/admin/ciclos/:codigo',
    name: 'AdminCiclos',
    component: GestionCiclos
  },
  {
    path: '/admin/cursos/:codigo/:ciclo',
    name: 'AdminCursos',
    component: GestionCursos
  },
  {
    path: '/admin/simulacros/:codigo/:ciclo',
    name: 'AdminSimulacros',
    component: GestionSimulacros
  },
  {
    path: '/admin/temas/:codigo/:ciclo/:curso',
    name: 'AdminTemas',
    component: GestionTemas
  },
  {
    path: '/admin/clases/:codigo/:ciclo/:curso',
    name: 'AdminClases',
    component: GestionClases
  },
  {
    path: '/admin/eventos/:codigo/:ciclo/:curso/:clase',
    name: 'AdminEventos',
    component: AdminEventos
  },
  {
    path: '/admin/temas/:codigo/:ciclo/:curso/:tema',
    name: 'AdminTemasDetalle',
    component: AdminTemasDetalle
  },
  {
    path: '/admin/usuarios',
    name: 'AdminUsuarios',
    component: GestionUsuarios
  },
  {
    path: '/admin/usuariosreporte',
    name: 'AdminReporteUsuarios',
    component: AdminReporteUsuarios
  },
  {
    path: '/admin/usuarios/:login',
    name: 'AdminUsuariosDetalle',
    component: AdminUsuariosDetalle
  },
  {
    path: '/admin/ciclos/:codigo/:ciclo',
    name: 'AdminCiclosDetalle',
    component: AdminCiclosDetalle
  },
  {
    path: '/admin/cursos/:codigo/:ciclo/:curso',
    name: 'AdminCursosDetalle',
    component: AdminCursosDetalle
  },
  {
    path: '/admin/videocopy/:codigo',
    name: 'AdminCopiaVideo',
    component: AdminCopiaVideo
  },
  {
    path: '/admin/simulacros/:codigo/:ciclo/:simulacro',
    name: 'AdminSimulacrosDetalle',
    component: AdminSimulacrosDetalle
  },
  {
    path: '/admin/simulacronuevo/:codigo/:ciclo',
    name: 'AdminNuevoSimulacro',
    component: AdminNuevoSimulacro
  },
  {
    path: '/:origen/cursos/:codigo/:ciclo/:curso',
    name: 'CursosTemas',
    component: CursosTemas
  },
  {
    path: '/:origen/cursos/:codigo/:ciclo/:curso/:tema',
    name: 'CursosArchivos',
    component: CursosArchivos
  },
  {
    path: '/simulacros/:ciclo/:curso/:simulacro',
    name: 'CursosSimulacros',
    component: CursosSimulacros
  },
  {
    path: '/simulacrodetalle/:ciclo/:simulacro',
    name: 'SimulacroDetalle',
    component: SimulacroDetalle
  },
  {
    path: '/notas/:codigo/:ciclo',
    name: 'CursosNotas',
    component: CursosNotas
  },
  {
    path: '/system',
    name: 'SystemInfo',
    component: SystemInfo
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router