<template>
    <div>
        <Header></Header>
        <div class="container">
            <h4>Detalle Usuario: {{ nuevoUsuario ? 'Nuevo Usuario' : form.login }}</h4>
            <div v-if="nuevoUsuario" class="col-sm-12">
                <label class="form-label">Login:</label>
                <input v-model="form.login" class="form-control" placeholder="Login">
            </div>
            <div class="col-sm-12">
                <label class="form-label">Nombre:</label>
                <input v-model="form.nombre" class="form-control" placeholder="Nombre">
            </div>
            <div class="col-sm-12">
                <label class="form-label">DNI:</label>
                <input v-model="form.dni" class="form-control" placeholder="DNI">
            </div>
            <div class="col-sm-12">
                <label class="form-label">Email:</label>
                <input v-model="form.email" class="form-control" placeholder="Email">
            </div>
            <div class="col-sm-12">
                <label class="form-label">Numero:</label>
                <input v-model="form.numero" class="form-control" placeholder="Numero">
            </div>
            <div class="col-sm-12">
                <label class="form-label">Vencimiento:</label>
                <input v-model="form.vencimiento" type="date" class="form-control" placeholder="Vencimiento">
            </div>
            <div v-if="!nuevoUsuario">
                <label class="form-label"><strong>Cambiar contraseña</strong></label>
                <div class="input-group">
                    <input v-model="inputPassword" disabled type="text" class="form-control"/>
                    <button @click="updatePassword" class="btn btn-sm btn-success">Cambiar</button>
                </div>
            </div>
            <div>
                <br>
                <label class="form-label">Perfiles:</label>
                <br>
                <label>
                    <input v-model="form.perfiles" value="ADMIN" type="checkbox" class="form-check-input"/>
                    Administrador
                </label>&nbsp;&nbsp;&nbsp;
                <label>
                    <input v-model="form.perfiles" value="PROFESOR" type="checkbox" class="form-check-input"/>
                    Profesor
                </label>&nbsp;&nbsp;&nbsp;
                <label >
                    <input v-model="form.perfiles" value="ALUMNO" type="checkbox" class="form-check-input"/>
                    Alumno
                </label>
            </div>
            <div v-if="!nuevoUsuario">
                <br>
                <label class="form-label">Estado:</label>
                <br>
                <label>
                    <input v-model="form.activo" type="checkbox" class="form-check-input"/>
                    Activo
                </label>&nbsp;&nbsp;&nbsp;
            </div>
            <div v-if="esAlumno">
                <br>
                <h5 class="form-label">Ciclos Asignados Alumno:</h5>
                <div class="col-sm-12">
                    <label class="form-label">Universidad:</label>
                    <select v-model="universidadAlumno" class="form-control input-sm">
                        <option v-for="(item,index) in this.universidades" :key="index" :value="index">
                            {{ item }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Ciclo:</label>
                    <div class="input-group">
                        <select v-model="cicloAlumno" class="form-control input-sm">
                            <option v-for="(item,index) in this.selectCicloAlumno" :key="index" :value="index">
                                {{ item }}
                            </option>
                        </select>
                        <button @click="addCicloAlumno" class="btn btn-sm btn-success">Agregar</button>
                    </div>
                </div>
                <br>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Universidad</th>
                            <th>Ciclo</th>
                            <th>Retirar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.form.ciclosAlumno" :key="item">
                            <td>{{ infoCiclos[item].universidad }}</td>
                            <td>{{ infoCiclos[item].ciclo }}</td>
                            <td class="text-center">
                                <button @click="removeCicloAlumno(index)" class="btn btn-sm btn-danger">
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>

                <h5 class="form-label">Cursos Asignados Alumno:</h5>
                <div class="col-sm-12">
                    <label class="form-label">Universidad:</label>
                    <select v-model="extraUniversidadAlumno" class="form-control input-sm">
                        <option v-for="(item,index) in this.universidades" :key="index" :value="index">
                            {{ item }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Ciclo:</label>
                    <select v-model="extraCicloAlumno" class="form-control input-sm">
                        <option v-for="(item,index) in this.extraSelectCicloAlumno" :key="index" :value="index">
                            {{ item }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Curso:</label>
                    <div class="input-group">
                        <select v-model="extraCursoAlumno" class="form-control input-sm">
                            <option v-for="(item,index) in this.extraSelectCursoAlumno" :key="index" :value="index">
                            {{ item }}
                            </option>
                        </select>
                        <button @click="addCursoAlumno" class="btn btn-sm btn-success">Agregar</button>
                    </div>
                </div>
                <br>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Universidad</th>
                            <th>Ciclo</th>
                            <th>Curso</th>
                            <th>Retirar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.form.cursosAlumno" :key="item">
                            <td>{{ infoCursos[item].universidad }}</td>
                            <td>{{ infoCursos[item].ciclo }}</td>
                            <td>{{ infoCursos[item].curso }}</td>
                            <td class="text-center">
                                <button @click="removeCursoAlumno(index)" class="btn btn-sm btn-danger">
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="esProfesor">
                <br>
                <h5 class="form-label">Cursos Asignados Profesor:</h5>
                <div class="col-sm-12">
                    <label class="form-label">Universidad:</label>
                    <select v-model="universidadProfesor" class="form-control input-sm">
                        <option v-for="(item,index) in this.universidades" :key="index" :value="index">
                            {{ item }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Ciclo:</label>
                    <select v-model="cicloProfesor" class="form-control input-sm">
                        <option v-for="(item,index) in this.selectCicloProfesor" :key="index" :value="index">
                            {{ item }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Curso:</label>
                    <div class="input-group">
                        <select v-model="cursoProfesor" class="form-control input-sm">
                            <option v-for="(item,index) in this.selectCursoProfesor" :key="index" :value="index">
                            {{ item }}
                            </option>
                        </select>
                        <button @click="addCursoProfesor" class="btn btn-sm btn-success">Agregar</button>
                    </div>
                </div>
                <br>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Universidad</th>
                            <th>Ciclo</th>
                            <th>Curso</th>
                            <th>Retirar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.form.cursosProfesor" :key="item">
                            <td>{{ infoCursos[item].universidad }}</td>
                            <td>{{ infoCursos[item].ciclo }}</td>
                            <td>{{ infoCursos[item].curso }}</td>
                            <td class="text-center">
                                <button @click="removeCursoProfesor(index)" class="btn btn-sm btn-danger">
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
            <div class="text-center col-xs-12 listButton">
                <button @click="saveNuevoUsuario" v-if="nuevoUsuario" class="btn btn-sm btn-success">Guardar</button> 
                <button @click="saveEditaUsuario" v-if="!nuevoUsuario" class="btn btn-sm btn-success">Guardar</button> 
                <router-link to="/admin/usuarios" class="btn btn-sm btn-primary btnAtras" tag="button">
                    Atras
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>

import Header from '../components/Header.vue'

export default {
    components: { Header },
    data(){
        return{
            form:{
                login: this.$route.params.login,
                nombre: '',
                dni: '',
                email: '',
                numero: '',
                vencimiento: '2022-06-06',
                perfiles: [],
                action: (this.$route.params.login==='new' ? 'newAdminUser' : 'editAdminUser'),
                ciclosAlumno: [],
                cursosAlumno: [],
                cursosProfesor: [],
                activo: '1'
            },
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: '',
            universidades: {},
            ciclos: {},
            cursos: {},
            universidadAlumno: '',
            cicloAlumno: '',
            universidadProfesor: '',
            cicloProfesor: '',
            cursoProfesor: '',
            inputPassword: '',

            extraUniversidadAlumno: '',
            extraCicloAlumno: '',
            extraCursoAlumno: ''
        }
    },
    computed:{
        nuevoUsuario(){
            return (this.$route.params.login==='new');
        },
        esAlumno(){
            return (this.form.perfiles.indexOf('ALUMNO')>-1);
        },
        esProfesor(){
            return (this.form.perfiles.indexOf('PROFESOR')>-1);
        },
        selectCicloAlumno(){
            return this.ciclos[this.universidadAlumno];
        },
        extraSelectCicloAlumno(){
            return this.ciclos[this.extraUniversidadAlumno];
        },
        extraSelectCursoAlumno(){
            return this.cursos[this.extraCicloAlumno];
        },
        selectCicloProfesor(){
            return this.ciclos[this.universidadProfesor];
        },
        selectCursoProfesor(){
            return this.cursos[this.cicloProfesor];
        },
        infoCiclos(){
            let tmpInfo={};
            for( let key in this.ciclos ){
                for( let key2 in this.ciclos[key] )
                    tmpInfo[key2]={universidad:this.universidades[key], ciclo:this.ciclos[key][key2]};
            }
            return tmpInfo;
        },
        infoCursos(){
            let tmpInfo={};
            for( let key in this.ciclos ){
                for( let key2 in this.ciclos[key] ){
                    for( let key3 in this.cursos[key2] )
                        tmpInfo[key3]={universidad:this.universidades[key], ciclo:this.ciclos[key][key2], curso: this.cursos[key2][key3]};
                }
            }
            return tmpInfo;
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminUsuarios', {
            login: this.$route.params.login,
            getExtraInfo: '1',
            action: 'getUsuarioDetalle'
        })
        .then( data=> {
            if( !this.nuevoUsuario ){
                this.form.nombre = data.data.nombre;
                this.form.dni = data.data.dni;
                this.form.email = data.data.email;
                this.form.numero = data.data.numero;
                this.form.vencimiento = data.data.vencimiento;
                this.form.perfiles = (data.data.perfiles==null ? [] : data.data.perfiles);
                this.form.ciclosAlumno = data.data.ciclos_alumno;
                this.form.cursosProfesor = data.data.cursos_profesor;
                this.form.cursosAlumno = data.data.cursos_alumno;
                this.form.activo = (data.data.status=='1');
            }else
                this.form.login = '';
            //extras de select
            this.universidades = data.universidades;
            this.ciclos = data.ciclos;
            this.cursos = data.cursos;
        });
        this.base=this;
    },
    methods:{
        saveNuevoUsuario(){
            this.$store.dispatch('ajaxAdminUsuarios', this.form)
            .then( data=> {
                this.$helpers.executeMessage(this, data, 60000);
            });
        },
        saveEditaUsuario(){
            //cambio previo a guardado
            let copFrm = JSON.parse(JSON.stringify(this.form));
            copFrm['activo'] = (copFrm['activo'] ? '1' : '0');
            this.$store.dispatch('ajaxAdminUsuarios', copFrm)
            .then( data=> {
                this.$helpers.executeMessage(this, data);
            });
        },
        addCicloAlumno(){
            if( this.form.ciclosAlumno.indexOf(this.cicloAlumno)>-1 )
                return;
            this.form.ciclosAlumno.push(this.cicloAlumno);
        },
        addCursoProfesor(){
            if( this.form.cursosProfesor.indexOf(this.cursoProfesor)>-1 )
                return;
            this.form.cursosProfesor.push(this.cursoProfesor);
        },
        removeCicloAlumno( index ){
            this.form.ciclosAlumno.splice(index, 1);
        },
        removeCursoProfesor( index ){
            this.form.cursosProfesor.splice(index, 1);
        },
        updatePassword(){
            this.$store.dispatch('ajaxAdminUsuarios', {
                action: 'updatePassword',
                login: this.form.login
            })
            .then( data=> {
                if( data.result=='1' )
                    this.inputPassword=data.msg;
                else
                    this.inputPassword=data.error_msg;
            });
        },
        addCursoAlumno(){
            if( this.form.cursosAlumno.indexOf(this.extraCursoAlumno)>-1 )
                return;
            this.form.cursosAlumno.push(this.extraCursoAlumno);
        },
        removeCursoAlumno(index){
            this.form.cursosAlumno.splice(index, 1);
        }
    }
}
</script>
<style scoped>
   .listButton{
       margin-top: 10px;
   }
   .btnAtras{
       margin-left: 4px;
   }
</style>