<template>
    <div>
        <Header></Header>
        <div>
            <div class="text-center list-button">
                <router-link :to="{name:'AdminUsuariosDetalle', params:{login: 'new'}}" class="btn btn-sm btn-primary" tag="button">Nuevo Usuario</router-link>&nbsp;
                <router-link :to="{name:'AdminReporteUsuarios'}" class="btn btn-sm btn-success" tag="button">Reporte Asistencia</router-link>
            </div>
            <div class="container">
                <div class="col-sm-4">
                    <input v-model="filter" type="text" class="form-control" placeholder="Buscar..."/>
                    <br>
                </div>
                <table class="table table-bordered">
                    <thead>
                        <th>Usuario</th>
                        <th>Nombre</th>
                        <th>Perfiles</th>
                        <th>Estado</th>
                        <th>Validez</th>
                        <th>Detalle/Editar</th>
                    </thead>
                    <tbody>
                        <tr v-for="data in filteredData" :key="data.id_tema">
                            <td>{{ data.login }}</td>
                            <td>{{ data.nombre }}</td>
                            <td>{{ data.perfiles }}</td>
                            <td>{{ data.status }}</td>
                            <td>{{ data.vencimiento }}</td>
                            <td class="text-center">
                                <router-link :to="{name:'AdminUsuariosDetalle', params:{login: data.login}}" class="btn btn-sm btn-primary" tag="button">
                                    <i class="bi bi-pencil"></i>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            adminUsuarios: [],
            filter: ''
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminUsuarios', {action: 'getListaUsuarios'})
        .then( data =>{
            this.adminUsuarios = data.data;
        });
    },
    computed: {
        filteredData(){
            return this.adminUsuarios.filter(row => {
                const login = row.login.toLowerCase();
                const nombre = row.nombre.toLowerCase();
                const searchTerm = this.filter.toLowerCase();
                return login.includes(searchTerm) || nombre.includes(searchTerm);
            });
        }
    }
}
</script>
<style scoped>
    .list-button{
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>