<template>
    <div>
        <Header></Header>
        <div class="container">
            <br>
            <h2 class="text-center">Copia de Videos</h2>
            <div class="row">
                <h5>Datos de Origen:</h5>
                <div class="col-sm-4">
                    <label for="">Ciclo:</label>
                    <select @change=" formData.origen_curso=0; formData.origen_tema=0; " v-model="formData.origen_ciclo" class="form-control">
                        <option value="0">--Elija un Ciclo--</option>
                        <option v-for="(item,index) in ciclos" :key="index" :value="item.id_ciclo">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label for="">Curso:</label>
                    <select @change=" formData.origen_tema=0; " v-model="formData.origen_curso" class="form-control">
                        <option value="0">--Elija un Curso--</option>
                        <option v-for="(item,index) in origenCurso" :key="index" :value="item.id_curso">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label for="">Tema:</label>
                    <select v-model="formData.origen_tema" class="form-control">
                        <option value="0">--Elija un Tema--</option>
                        <option v-for="(item,index) in origenTema" :key="index" :value="item.id_tema">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <h5>Datos de Destino:</h5>
                <div class="col-sm-4">
                    <label for="">Ciclo:</label>
                    <select @change=" formData.destino_curso=0; formData.destino_tema=0; " v-model="formData.destino_ciclo" class="form-control">
                        <option value="0">--Elija un Ciclo--</option>
                        <option v-for="(item,index) in ciclos" :key="index" :value="item.id_ciclo">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label for="">Curso:</label>
                    <select @change=" formData.destino_tema=0; " v-model="formData.destino_curso" class="form-control">
                        <option value="0">--Elija un Curso--</option>
                        <option v-for="(item,index) in destinoCurso" :key="index" :value="item.id_curso">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label for="">Tema:</label>
                    <select v-model="formData.destino_tema" class="form-control">
                        <option value="0">--Elija un Tema--</option>
                        <option v-for="(item,index) in destinoTema" :key="index" :value="item.id_tema">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div v-if="formOk" class="alert alert-success" role="alert">
                    {{ msgOk }}
                </div>
                <div v-if="formError" class="alert alert-danger" role="alert">
                    {{ msgError }}
                </div>
                <div class="text-center">
                    <button @click="saveData" class="btn btn-success">Copiar</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    computed:{
        origenCurso(){
            if( this.formData.origen_ciclo==0 ){
                return [];
            }
            return this.cursos.filter( (item)=>{ return item.id_ciclo==this.formData.origen_ciclo; } );
        },
        origenTema(){
            if( this.formData.origen_curso==0 ){
                return [];
            }
            return this.temas.filter( (item)=>{ return item.id_curso==this.formData.origen_curso; } );
        },
        destinoCurso(){
            if( this.formData.destino_ciclo==0 ){
                return [];
            }
            return this.cursos.filter( (item)=>{ return item.id_ciclo==this.formData.destino_ciclo; } );
        },
        destinoTema(){
            if( this.formData.destino_curso==0 ){
                return [];
            }
            return this.temas.filter( (item)=>{ return item.id_curso==this.formData.destino_curso; } );
        }
    },
    data(){
        return{
            ciclos: [],
            cursos: [],
            temas: [],
            formData: {
                origen_ciclo: 0,
                origen_curso: 0,
                origen_tema: 0,
                destino_ciclo: 0,
                destino_curso: 0,
                destino_tema: 0,
                action: 'copyVideos',
                codigoUni: this.$route.params.codigo
            },
            formOk: false,
            formError: false
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            action: 'getInfoCopiaVideo',
            codigoUni: this.$route.params.codigo,
        })
        .then(data=>{
            this.ciclos = data.data.ciclos;
            this.cursos = data.data.cursos;
            this.temas  = data.data.temas;
        });
    },
    methods:{
        saveData(){
            let okForm = true;
            Object.keys(this.formData).forEach((item)=>{
                if( item!='action' && item!='codigoUni' && this.formData[item]==0 ){
                    okForm = false;
                }
            });

            if( !okForm ){
                this.$helpers.executeMessage( this, { result: "0", error_msg:"Complete todos los campos" } );
                return;
            }

            if( !confirm("Se procederá a copiar los videos, desea continuar?") ){
                return false;
            }

            this.$store.dispatch('ajaxAdminCursos', this.formData)
            .then(data=>{
                this.$helpers.executeMessage( this, data );
            });
        }
    }
}
</script>


<style scoped>

</style>