<template>
  <div class="container">
    <div class="row container-total">
      <div class="col-sm-9">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="logo-title">Con nosotros no necesitas academias</h1>
          </div>
        </div>
        <div class="row">
          <div style="padding-left: 0px; padding-right: 0px;" class="col-sm-7">
            <div id="carouselLogros" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="@/assets/foto_new_1.jpg" class="d-block w-100 img-logros" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="@/assets/foto_new_2.jpg" class="d-block w-100 img-logros" alt="...">
                </div>
                <div class="carousel-item">
                  <img src="@/assets/foto_new_3.jpg" class="d-block w-100 img-logros" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselLogros" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselLogros" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-sm-5">
            <img class="logo-main" src="@/assets/logo_new_bg.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-center ">
            <div class="div-ciclo">
              <span>CICLO SEMESTRAL</span>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="div-ciclo">
              <span>CICLO PARALELO CEPRE-UNI</span>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="div-ciclo">
              <span>CICLO NOCTURNO FIS-QUIM</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 div-grupo">
        <br>
        <div class="text-center">
          <h5>GRUPO DE ESTUDIO</h5>
        </div>
        <form v-on:submit.prevent="login">
          <div class="col-sm-12 margin-top-bottom">
            <input v-model="user" type="text" class="form-control" placeholder="Usuario"/>
          </div>
          <div class="col-sm-12 margin-top-bottom">
            <input v-model="password" type="password" class="form-control" placeholder="Contraseña"/>
          </div>
          <div class="col-sm-12 margin-top-bottom text-center">
            <input :disabled="!formOk" type="submit" class="btn btn-success btn-login" value="Login"/>
          </div>
        </form>
        <div v-if="error" class="alert alert-danger" role="alert">
          {{ error_msg }}
        </div>

        <div>
          <div class="row">
            <div class="col-sm-6 text-center">Para mayor información contactanos con un click</div>
            <div class="col-sm-6 text-center">
              <img @click="openWhatsapp" src="@/assets/whatsapp.logo.png" class="img-whatsapp">
            </div>
            <div class="col-sm-12 text-center margin-top">
              <img src="@/assets/qr.png" class="img-qr" alt="">
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="div-arrow">
        <h5 class="h5-label-redes">NUESTRAS REDES</h5>
      </div>
      <div class="div-social">
        <img class="image-triangle" src="@/assets/triangle.rotate.png" alt="">
        <a class="link-social" target="_blank" href="https://www.youtube.com/channel/UC4Hj5fj1yVc6FoPaAr_KaVQ">
          <img src="@/assets/logo.yt.png" alt="">
        </a>
        <a class="link-social" target="_blank" href="https://www.tiktok.com/@refuerzo_de_nivel">
          <img src="@/assets/logo.tiktok.png" alt="">
        </a>
        <a class="link-social" target="_blank" href="https://instagram.com/refuerzodenivel">
          <img src="@/assets/logo.ig.png" alt="">
        </a>
        <a class="link-social" target="_blank" href="https://www.facebook.com/VERDADERAMENTEUNI">
          <img src="@/assets/logo.fb.png" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>



import axios from 'axios'
export default {
  name: 'Home',
  data(){
    return {
      user: "",
      password: "",
      error: false,
      error_msg: ""
    }
  },
  methods:{
    login(){
      axios.post(this.$store.getters.urlApiLogin, this.formData)
      .then( data =>{
        if( data.data.result=="1" ){
          localStorage.token = data.data.data;
          this.$router.push('dashboard');
        }else{
          this.error = true;
          this.error_msg = data.data.error_msg;
        }
      })
      .catch( e =>{
        console.log(e);
      });
    },
    openWhatsapp(){
      window.open("https://api.whatsapp.com/send/?phone=51924766063", '_blank').focus();
    }
  },
  computed: {
    formOk(){
      return true;
    },
    formData(){
      let formData=new FormData();
      formData.append('user', this.user);
      formData.append('password', this.password);
      return formData;
    },
    formDataVerify(){
      let formData=new FormData();
      formData.append('token', localStorage.token);
      return formData;
    }
  },
  mounted() {
    if( typeof(localStorage.token)==='string' && localStorage.token.trim().length==12 ){
      axios.post(this.$store.getters.urlApiLoginVerify, this.formDataVerify)
      .then( data => {
        if( data.data.result=="1" )
          this.$router.push('dashboard');
      })
      .catch( e =>{
        console.log(e);
      });
    }
  }
}
</script>

<style scoped>
  .margin-top-bottom{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  input::placeholder {
    text-align: center;
  }
  .img-whatsapp{
    width: 80px;
    cursor: pointer;
  }
  .img-qr{
    width: calc( 100% - 10px );
  }

  .logo-title{
    font-family: 'Brush Script MT', cursive;
    font-size: 60px;
  }

  .logo-main{
    width: calc( 100% - 10px );
  }

  .btn-login{
    width: 100%;
  }

  .div-grupo{
    background-color: rgb(240, 240, 230);
  }

  .margin-top{
    margin-top: 10px;
  }

  .div-arrow{
    float: left;
    background-color: black;
    width: 190px;
    padding-left: 2px;
    padding-right: 0px;
  }

  .div-social{
    float: left;
    width: calc( 100% - 190px );
    padding-left: 0px;
    background: linear-gradient(90deg, rgba(255,87,0,1) 0%, rgba(244,243,95,1) 100%);
  }

  .h5-label-redes{
    margin-top: 6px;
    color: white;
    font-weight: bold;
  }
  .image-triangle{
    height: 40px;
  }
  .link-social{
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    float: right;
  }
  .div-ciclo{
    word-wrap: break-word;
    height: 60px;
    text-align: center;
    margin-top: 10px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: #ffd64e;
    line-height: 60px;
    font-weight: bold;
    font-size: 20px;
  }
  .div-ciclo > span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
  

  @media (max-width: 575px) {
    .img-logros{
      height: 280px;
      width: auto !important;
      margin: auto;
    }
  }
  @media (max-width: 1280px) {
    .img-logros{
      height: 320px;
      width: auto !important;
      margin: auto;
    }
  }
</style>