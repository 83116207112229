<template>
    <div>
        <Header></Header>
        <div class="text-center">
            <router-link :to="{name:'AdminCiclosDetalle', params:{codigo: codigoUni, ciclo:'new'}}" class="btn btn-sm btn-primary" tag="button" id="btnNuevoCiclo">
                Nuevo Ciclo
            </router-link>
            &nbsp;
            <router-link :to="{name:'AdminCopiaVideo', params:{codigo: codigoUni}}" class="btn btn-sm btn-warning" tag="button">
                Copiar Videos
            </router-link>
        </div>
        <div class="container">
            <h5>
                <router-link to="/admin/universidades" class="link-dark">Universidades</router-link> / 
                <router-link :to="{name:'AdminCiclos', params:{codigo: codigoUni}}" class="link-dark">{{ codigoUni }}</router-link>
            </h5>
            <table class="table table-bordered">
                <thead>
                    <th>Nombre</th>
                    <th>F. Inicio</th>
                    <th>F. Fin</th>
                    <th>Cursos</th>
                    <th>Simulacros</th>
                    <th>Editar</th>
                </thead>
                <tbody>
                    <tr v-for="data in this.adminCiclos" :key="data.id_ciclo" :class="data.estado=='0' ? 'danger' : ''">
                        <td>{{ data.label }}</td>
                        <td>{{ data.fecha_inicio }}</td>
                        <td>{{ data.fecha_fin }}</td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminCursos', params:{codigo: codigoUni, ciclo:data.id_ciclo}}" class="btn btn-sm btn-primary" tag="button">
                                <i class="bi bi-book"></i>
                            </router-link>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminSimulacros', params:{codigo: codigoUni, ciclo:data.id_ciclo}}" class="btn btn-sm btn-info" tag="button">
                                <i class="bi bi-book"></i>
                            </router-link>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminCiclosDetalle', params:{codigo: codigoUni, ciclo:data.id_ciclo}}" class="btn btn-sm btn-success" tag="button">
                                <i class="bi bi-pencil"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            action: 'getListaCiclos',
            codigoUni: this.$route.params.codigo,
        })
        .then(data=>{
            this.adminCiclos = data.data;
        });
    },
    data(){
        return {
            codigoUni: this.$route.params.codigo,
            adminCiclos: []
        }
    }
}
</script>
<style scoped>
    #btnNuevoCiclo{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .danger{
        background-color: rgb(252, 231, 231);
    }
</style>