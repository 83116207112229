<template>
    <div>
        <Header></Header>
        <div class="container">
            <h4>Detalle Tema: {{ this.labelOriginal }}</h4>
            <div class="col-sm-12">
                <label class="form-label">Nombre:</label>
                <input v-model="form.label" class="form-control" placeholder="Nombre">
            </div>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
            <div class="text-center listButton">
                <button @click="saveTema" class="btn btn-sm btn-success">Guardar</button> 
                <router-link class="btn btn-sm btn-primary btnAtras" :to="{name:'AdminTemas', params:{codigo: this.form.codigoUni, ciclo: this.form.codigoCiclo, curso:this.form.codigoCurso}}">
                    Atras
                </router-link>
            </div>
            <div v-if="!nuevoTema">
                <h5 class="files-h5">Archivos:</h5>
                <div class="col-sm-12">
                    <label class="form-file">Archivo:</label>
                    <input @change="onFileChange" class="form-control" type="file" id="fileUpload"/>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Nombre (Opcional):</label>
                    <input v-model="formFile.nombre" class="form-control" placeholder="Nombre (Opcional)"/>
                </div>
                <div v-if="formOkFile" class="alert alert-success" role="alert">
                    {{ msgOkFile }}
                </div>
                <div v-if="formErrorFile" class="alert alert-danger" role="alert">
                    {{ msgErrorFile }}
                </div>
                <div class="text-center listButton">
                    <button @click="saveFile" class="btn btn-sm btn-success">Guardar Archivo</button>
                </div>
                <table class="table table-bordered table-files">
                    <thead>
                        <tr>
                            <th>Archivo</th>
                            <th>Quitar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.archivos" :key="index">
                            <td><a :href="$store.getters.urlApiFiles+'?id='+item.file+'&token='+$store.getters.getToken" target="_blank">{{ item.nombre }}</a></td>
                            <td class="text-center">
                                <button @click="removeFile(item.file)" class="btn btn-sm btn-danger">
                                    <i class="bi bi-file-earmark-x-fill"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h5 class="files-h5">Clase Grabada:</h5>
                <div class="text-center">
                    <div v-for="(item, index) in videos" :key="index">
                        <video oncontextmenu="return false;" v-if="item.type=='external_server'" width="640" height="360" controls>
                            <source :src="item.url" type="video/mp4">
                        </video>
                        <video oncontextmenu="return false;" v-if="item.type=='api'" width="640" height="360" controls>
                            <source :src="$store.getters.urlVideoFiles+item.file" type="video/mp4">
                        </video>
                        <div oncontextmenu="return false;" v-if="item.type=='google_drive'" style="width: 640px; height: 360px; position: relative; left: 50%; transform: translateX(-50%); margin-bottom: 15px;">
                            <a target="_blank" :href="'https://drive.google.com/file/d/'+item.id+'/preview'">
                                {{ 'https://drive.google.com/file/d/'+item.id+'/preview' }}
                            </a>
                            <iframe allowfullscreen v-if="item.type=='google_drive'" width="640" height="360" :src="'https://drive.google.com/file/d/'+item.id+'/preview'" frameborder="0" scrolling="no" seamless=""></iframe>
                            <!--<div style="width: 80px; height: 80px; position: absolute; opacity: 0; right: 0px; top: 0px;">&nbsp;</div>-->
                        </div>
                        <br>
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    computed:{
        nuevoTema(){
            return (this.$route.params.tema==='new');
        }
    },
    data(){
        return{
            form:{
                label: '',
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoTema: this.$route.params.tema,
                action: (this.$route.params.tema==='new' ? 'newAdminTema' : 'editAdminTema')
            },
            formFile:{
                file: null,
                nombre: '',
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoTema: this.$route.params.tema,
                action: 'editAdminTemaFileUpload'
            },
            formFileRemove:{
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoTema: this.$route.params.tema,
                action: 'editAdminTemaFileRemove',
                fileToken: '',
            },
            archivos: [],
            videos: [],
            labelOriginal: '',
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: '',
            formErrorFile: false,
            formOkFile: false,
            msgErrorFile: '',
            msgOkFile: ''
        }
    },
    mounted(){
        if( !this.nuevoTema ){
            this.$store.dispatch('ajaxAdminCursos', {
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoTema: this.$route.params.tema,
                action: 'getTemaDetalle'
            })
            .then( data=> {
                this.form.label = data.data.label;
                this.labelOriginal = data.data.label;
                this.archivos = data.data.archivos;
                this.videos = data.data.videos;
            });
        }else{
            this.labelOriginal='Nuevo Tema';
        }
    },
    methods:{
        saveTema(){
            this.$store.dispatch('ajaxAdminCursos', this.form)
            .then( data=> {
                this.$helpers.executeMessage( this, data );
            });
        },
        onFileChange(e){
            this.formFile.file = e.target.files[0];
        },
        saveFile(){
            this.$store.dispatch('ajaxAdminCursos', this.formFile)
            .then( data=> {
                if( data.result=="1" ){
                    //reseteando y data
                    this.archivos = data.data;
                    document.getElementById("fileUpload").value = "";
                    this.formFile.nombre = "";
                    //mensajes de aviso
                    this.formOkFile = true;
                    this.msgOkFile = data.msg; 
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }else{
                    this.formErrorFile = true;
                    this.msgErrorFile = data.error_msg;
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }
            });
        },
        removeFile( file ){
            this.formFileRemove.fileToken = file;
            this.$store.dispatch('ajaxAdminCursos', this.formFileRemove)
            .then( data=> {
                if( data.result=="1" ){
                    this.formOkFile = true;
                    this.msgOkFile = data.msg;
                    this.archivos = data.data;
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }else{
                    this.formErrorFile = true;
                    this.msgErrorFile = data.error_msg;
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }
            });
        }
    },
}
</script>
<style scoped>
    .files-h5{
        margin-top: 10px;
    }
    .table-files{
        margin-top: 10px;
    }
    .listButton{
        margin-top: 10px;
    }
    .btnAtras{
       margin-left: 4px;
    }
</style>