export default {
  executeMessage( base, data, t1=2000, t2=2000 ) {    
    if( data.result=="1" ){
      base.formOk=true;
      base.msgOk=data.msg;
      setTimeout(()=>{ base.formOk=false; base.formError=false; }, t1);
    }else{
      base.formError=true;
      base.msgError=data.error_msg;
      setTimeout(()=>{ base.formOk=false; base.formError=false; }, t2);
    }
  },
  getListaSemana(){
    return {
      '0': 'DOMINGO',
      '1': 'LUNES',
      '2': 'MARTES',
      '3': 'MIERCOLES',
      '4': 'JUEVES',
      '5': 'VIERNES',
      '6': 'SABADO'
    };
  }
}