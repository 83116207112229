<template>
    <div>
        <Header></Header>
        <div class="row card-container">
            <router-link v-for="(data, index) in this.universidades" :to="{name:'AdminCiclos', params:{codigo: data.codigo}}" :key="index" class="col-sm-6 card-list" tag="div">
                <div class="card">
                    <div class="row g-0">
                        <div class="col-md-4 text-center">
                            <img src="@/assets/logo.png" class="img-fluid rounded-start">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">{{ data.label+" ("+data.codigo+")" }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            universidades: []
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {action: 'getListaUniversidades'})
        .then(data=> {
            this.universidades = data.data;
        });
    }
}
</script>
<style scoped>
    #btnNuevaUniversidad{
        margin-top: 10px;
    }
    .container{
        margin-top: 10px;;
    }
    .card-container{
        margin: 10px;;
    }
    .card{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .card-list{
        cursor: pointer;
    }
</style>