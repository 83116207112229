<template>
    <div>
        <Header></Header>
        <div class="container">
            <div v-if="esAlumno">
                <h4>Alumno: {{ infoAlumno.nombre }}</h4>
                <hr class="separator">
                <div v-for="(itemCiclo, id_ciclo) in infoAlumno.data" :key="id_ciclo">
                    <h5 class="h5-bold">Ciclo: {{ itemCiclo.ciclo }}
                        &nbsp;<i class="bi bi-arrow-right"></i>&nbsp;
                        <router-link :to="{name:'CursosNotas', params:{codigo: itemCiclo.codigo_uni, ciclo:itemCiclo.codigo_ciclo}}">
                            Notas y Asistencia
                        </router-link>
                    </h5>
                    <br>
                    <h5>Clase de Hoy: {{ typeof(itemCiclo.clase_hoy)=='string' ? itemCiclo.clase_hoy : '' }}</h5>
                    <div class="row card-container" v-if="typeof(itemCiclo.clase_hoy)=='object'">
                        <div v-for="(item, index) in itemCiclo.clase_hoy" :key="index" class="col-sm-6 card-list">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">
                                        {{ item.curso+": "+item.tema }}
                                    </h5>
                                    <p class="card-text">
                                        {{ listaSemana[item.dia]+" "+item.horario }}
                                    </p>
                                    <p class="card-text">
                                        {{ item.detalle }}
                                    </p>
                                    <a :href="$store.getters.urlApiCursos+'clase.php?'+item.link_local+'&token='+$store.getters.getToken" class="btn btn-primary" target="_blank">Ir a la clase</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h5>Simulacros: {{ typeof(itemCiclo.simulacros)=='string' ? itemCiclo.simulacros : '' }}</h5>
                    <div class="row card-container" v-if="typeof(itemCiclo.simulacros)=='object'">
                        <div v-for="(item, index) in itemCiclo.simulacros" :key="index" class="col-sm-6 card-list">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">
                                        {{ item.label }}
                                    </h5>
                                    <h5 v-if="item.curso!='NO_CURSO'">
                                        {{ "Curso: "+item.curso }}
                                    </h5>
                                    <p class="card-text">
                                        {{ item.hora_ini+" - "+item.hora_fin }}
                                    </p>
                                    <p class="card-text">
                                        {{ item.detalle }}
                                    </p>
                                    <router-link v-if="item.inicio=='1'" :to="{name:'CursosSimulacros', params:{ciclo:item.id_ciclo, curso:item.id_curso, simulacro: item.id_simulacro}}" :key="index" class="btn btn-primary" tag="button">
                                        {{ item.finalizado=='1' ? 'Ver Resultados' : 'Rendir Simulacro' }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br>

                    <h5>Mis cursos: </h5>
                    <div class="row card-container">
                        <router-link  v-for="(item, index) in itemCiclo.cursos" :to="{name:'CursosTemas', params:{codigo: item.codigo_uni, ciclo: item.id_ciclo, curso: item.id_curso, origen:'alumno'}}" :key="index" class="col-sm-6 card-list" tag="div">
                            <div class="card text-dark bg-light mb-3">
                                <div class="card-header">{{ item.label }}</div>
                                <div class="card-body">
                                    <h5 class="card-title">Horarios:</h5>
                                    <p class="card-text" v-for="(horario, index) in item.horarios" :key="index">
                                        {{ horario.dia+" "+horario.hini+" - "+horario.hfin }}
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <hr class="separator">
                </div>
            </div>
            <div v-if="esProfesor">
                <h4>Profesor: {{ infoProfesor.nombre }}</h4>
                <h5>Clase de Hoy: {{ typeof(infoProfesor.clase_hoy)=='string' ? infoProfesor.clase_hoy : '' }}</h5>
                <div class="row card-container" v-if="typeof(infoProfesor.clase_hoy)=='object'">
                    <div v-for="(item, index) in infoProfesor.clase_hoy" :key="index" class="col-sm-6 card-list">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">
                                    {{ item.curso+": "+item.tema }}
                                </h5>
                                <p class="card-text">
                                    {{ listaSemana[item.dia]+" "+item.horario }}
                                </p>
                                <p class="card-text">
                                    {{ item.detalle }}
                                </p>
                                <a :href="item.link_reunion" class="btn btn-primary" target="_blank">Ir a la clase</a>
                                <br><br>
                                <label>Tema de Hoy:</label>
                                <div class="input-group col-sm-12">
                                    <select v-model="item.id_tema" class="form-control">
                                        <option selected disabled value='0'>--Elija un Tema--</option>
                                        <option v-for="(itemTema, indexTema) in item.info_tema" :key="indexTema" :value="indexTema">
                                            {{ itemTema }}
                                        </option>
                                    </select>
                                    <button @click="updateTema(index)" class="btn btn-success" type="button">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5>Mis cursos: </h5>
                <div class="row card-container">
                    <div  v-for="(item, index) in infoProfesor.cursos" :key="index" class="col-sm-6 card-list" tag="div">
                        <div class="card text-dark bg-light mb-3">
                            <div class="card-header">
                                {{ item.codigo_uni }} / {{ item.ciclo }} / {{ item.curso }}
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Horarios:</h5>
                                <p class="card-text" v-for="(horario, index) in item.horarios" :key="index">
                                    {{ horario.dia+" "+horario.hini+" - "+horario.hfin }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    name: "Dashboard",
    components: {
       Header
    },
    data(){
        return {
            infoDashboard: {},
            listaSemana: this.$helpers.getListaSemana()
        }
    },
    computed:{
        infoAlumno(){
            return this.infoDashboard.alumno;
        },
        infoProfesor(){
            return this.infoDashboard.profesor;
        },
        esAlumno(){
            if( Object.keys(this.infoDashboard).length==0 )
                return false;
            return typeof(this.infoDashboard.alumno)!='undefined';
        },
        esProfesor(){
            if( Object.keys(this.infoDashboard).length==0 )
                return false;
            return typeof(this.infoDashboard.profesor)!='undefined';
        }
    },
    mounted(){
        this.$store.dispatch('ajaxCursos', {action: 'getInfoDashboard'})
        .then(data=>{
            this.infoDashboard = data.data;
        });
    },
    methods:{
        updateTema(index){
            let codigoClase=this.infoProfesor.clase_hoy[index].id_clase;
            let codigoTema =this.infoProfesor.clase_hoy[index].id_tema;
            this.$store.dispatch('ajaxCursos', {action: 'updateTemaClase', codigoClase:codigoClase, codigoTema:codigoTema})
            .then(data=>{
                if( data.result=='1' )
                    this.infoProfesor.clase_hoy[index].tema = data.msg;
            });
        }
    }
}
</script>

<style scoped>
    .card-body{
        padding: 10px;
    }
    .card-text{
        margin-bottom: 8px;
    }
    .card-list{
        cursor: pointer;
        margin-bottom: 20px;
    }
    .h5-bold{
        font-weight: bold;
    }
    hr.separator{
        border-top: 3px dotted #bbb;
    }
</style>