import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import helpers from './helpers/index.js';

const plugin = {
  install (Vue) {
    Vue.prototype.$helpers = helpers;
  }
};
Vue.use(plugin);

Vue.config.productionTip = false
//usando los modulos
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
