<template>
    <div>
        <Header></Header>
        <div class="text-center">
            <router-link :to="{name:'AdminTemasDetalle', params:{codigo:codigo, ciclo:ciclo, curso:curso, tema:'new'}}" class="btn btn-sm btn-primary" tag="button" id="btnNuevoTema">
                Nuevo Tema
            </router-link>
        </div>
        <div class="container">
            <h5>
                <router-link to="/admin/universidades" class="link-dark">Universidades</router-link> / 
                <router-link :to="{name:'AdminCiclos', params:{codigo: codigo}}" class="link-dark">{{ codigo }}</router-link> /
                <router-link :to="{name:'AdminCursos', params:{codigo: codigo, ciclo: ciclo}}" class="link-dark">
                    {{ this.adminLabelCiclo }}
                </router-link> / 
                <router-link :to="{name:'AdminTemas', params:{codigo: codigo, ciclo: ciclo, curso:curso}}" class="link-dark">
                    {{ this.adminLabelCurso }}
                </router-link>
            </h5>
            <table class="table table-bordered">
                <thead>
                    <th>Nombre</th>
                    <th>Detalle/Editar</th>
                </thead>
                <tbody>
                    <tr v-for="data in this.adminTemas" :key="data.id_tema">
                        <td>{{ data.label }}</td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminTemasDetalle', params:{codigo:codigo, ciclo:ciclo, curso:curso, tema:data.id_tema}}" class="btn btn-sm btn-success" tag="button">
                                <i class="bi bi-pencil"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    data(){
        return{
            adminTemas: [],
            adminLabelCiclo: '',
            adminLabelCurso: ''
        }
    },
    components: { Header },
    computed:{
        codigo(){
            return this.$route.params.codigo;
        },
        ciclo(){
            return this.$route.params.ciclo;
        },
        curso(){
            return this.$route.params.curso;
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.codigo,
            codigoCiclo: this.ciclo,
            codigoCurso: this.curso,
            action: 'getListaTemas'
        })
        .then(data=>{
            this.adminTemas = data.data;
            this.adminLabelCiclo = data.label_1;
            this.adminLabelCurso = data.label_2;
        });
    }
}
</script>
<style scoped>
    #btnNuevoTema{
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>