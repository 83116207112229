<template>
    <div>
        <Header></Header>
        <div class="container">
            <h3>Gestion de Simulacros: {{ adminLabelCiclo }} &nbsp;
                <router-link :to="{name:'AdminNuevoSimulacro', params:{codigo: codigo, ciclo: ciclo}}" class="btn btn-sm btn-success" tag="button">
                    <i class="bi bi-file-earmark-plus"></i> Agregar Simulacro
                </router-link>
            </h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Dia</th>
                        <th>H. Inicio</th>
                        <th>H. Fin</th>
                        <th>N. Preguntas</th>
                        <th>P. Correcto</th>
                        <th>P. Incorrecto</th>
                        <th>Estado</th>
                        <th>Detalle</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in adminSimulacros" :key="data.id_simulacro">
                        <td>{{ data.label }}</td>
                        <td>{{ data.dia }}</td>
                        <td>{{ data.hora_ini }}</td>
                        <td>{{ data.hora_fin }}</td>
                        <td>{{ data.numero_preguntas }}</td>
                        <td>{{ data.puntaje_correcto }}</td>
                        <td>{{ data.puntaje_incorrecto }}</td>
                        <td>{{ data.estado=='1' ? 'Terminado' : 'En proceso' }}</td>
                        <td class="text-center">
                            <router-link :to="{name:'AdminSimulacrosDetalle', params:{codigo: codigo, ciclo: ciclo, simulacro:data.id_simulacro}}" class="btn btn-sm btn-success" tag="button">
                                <i class="bi bi-pencil"></i>
                            </router-link>
                        </td>
                        <td class="text-center">
                            <button @click="removeSimulacro(data.id_simulacro)" class="btn btn-sm btn-danger">
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            codigo: this.$route.params.codigo,
            ciclo: this.$route.params.ciclo,
            adminSimulacros: [],
            adminLabelCiclo: '',
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.codigo,
            codigoCiclo: this.ciclo,
            action: 'getListaSimulacros'
        })
        .then( data=>{
            this.adminSimulacros = data.data;
            this.adminLabelCiclo = data.label_1;
        });
    },
    methods: {
        removeSimulacro(id_simulacro){
            if( !confirm("Se procedera a BORRAR el simulacro, desea continuar?") )
                return false;
            this.$store.dispatch('ajaxAdminCursos', {
                codigoUni: this.codigo,
                codigoCiclo: this.ciclo,
                codigoSimulacro: id_simulacro,
                action: 'removeSimulacro'
            })
            .then( data=>{
                this.$helpers.executeMessage( this, data );
                this.adminSimulacros = data.data;
            });
        }
    }
}
</script>

<style scoped>

</style>