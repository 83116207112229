<template>
    <div>
        <Header></Header>
        <div class="text-center">
            <router-link to="/dashboard" class="btn btn-sm btn-primary btnAtras" tag="button">
                Atras
            </router-link>
        </div>
        <div class="container">
            <h5>Temas: {{ cursoLabelCurso }}</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Tema</th>
                        <th>Archivos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in cursosTemas" :key="item.id_tema">
                        <td>{{ item.label }}</td>
                        <td class="text-center">
                            <router-link :to="{name:'CursosArchivos', params:{ codigo: formTemas.codigoUni, ciclo: formTemas.codigoCiclo, curso: formTemas.codigoCurso, origen: formTemas.codigoOrigen, tema: item.id_tema }}" class="btn btn-sm btn-primary" tag="button">
                                <i class="bi bi-file-earmark"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            formTemas:{
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoOrigen: this.$route.params.origen,
                action: 'getInfoTemas'
            },
            cursoLabelCurso: '',
            cursosTemas: []
        }
    },
    mounted(){
        this.$store.dispatch('ajaxCursos', this.formTemas)
        .then(data=>{
            this.cursosTemas = data.data;
            this.cursoLabelCurso = data.label_2;
        });
    },
}
</script>
<style scoped>
    .container{
        margin-top: 10px;
    }
    .btnAtras{
        margin-top: 10px;
    }
</style>