<template>
    <div>
        <Header></Header>
        <div class="text-center listButton">
            <button @click="back" class="btn btn-sm btn-primary">Atras</button>
        </div>
        <div class="container">
            <h4>Clase:</h4>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <select v-model="tema" class="form-control">
                            <option v-for="(item,index) in lista_temas" :key="index" :value="item.id_tema">
                                {{ item.label }}
                            </option>
                        </select>
                        <button @click="saveTema" class="btn btn-primary" type="button">Guardar</button>
                    </div>
                </div>
            </div>
            <br>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
            <br>
            <h4>Eventos:</h4>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Hora</th>
                        <th>Evento</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in eventos" :key="index" :class="listClassEvents[item.event]">
                        <td>{{ item.hora }}</td>
                        <td>{{ listLabelEvents[item.event] }}</td>
                        <td>{{ item.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            eventos: [],
            listLabelEvents: {
                "meeting.started" : "Reunión inició",
                "meeting.participant_joined" : "Participante se unió",
                "meeting.sharing_started" : "Se empezó a compartir",
                "recording.started" : "Grabación inicio",
                "meeting.sharing_ended": "Se dejó de compartir",
                "meeting.participant_left": "Participante se retiró",
                "recording.stopped": "Grabación terminó",
                "meeting.ended": "Reunión terminó",
                "recording.completed": "Grabación procesada",
                "recording.paused": "Grabación pausada"
            },
            listClassEvents: {
                "meeting.started" : "info",
                "meeting.participant_joined" : "Participante se unió",
                "meeting.sharing_started" : "success",
                "recording.started" : "danger",
                "meeting.sharing_ended": "warning",
                "meeting.participant_left": "Participante se retiró",
                "recording.stopped": "primary",
                "meeting.ended": "brown",
                "recording.completed": "Grabación procesada",
                "recording.paused": "Grabación pausada"
            },
            tema: '0',
            lista_temas: [],
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    mounted(){
        //info de direccion
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.$route.params.codigo,
            codigoCiclo: this.$route.params.ciclo,
            codigoCurso: this.$route.params.curso,
            codigoClase: this.$route.params.clase,
            action: 'getListaEventos'
        })
        .then(data=>{
            this.eventos = data.data;
            this.tema = data.tema;
            this.lista_temas = data.lista_temas;
        });
    },
    methods:{
        back(){
            this.$router.go(-1);
        },
        saveTema(){
            let codigoClase = this.$route.params.clase;
            let codigoTema  = this.tema;
            this.$store.dispatch('ajaxAdminCursos', {
                action: 'updateTemaClase',
                codigoClase:codigoClase,
                codigoTema:codigoTema
            })
            .then(data=>{
                this.$helpers.executeMessage( this, data );
            });
        }
    }
}
</script>

<style scoped>
    .listButton{
        margin-top: 10px;
    }
    .info{
        background-color: rgb(194, 229, 243);
    }
    .success{
        background-color: rgb(207, 248, 211);
    }
    .danger{
        background-color: rgb(235, 172, 172);
    }
    .primary{
        background-color: rgb(133, 173, 247);
    }
    .warning{
        background-color: rgb(255, 200, 148);
    }
    .brown{
        background-color: rgb(173, 171, 171);
    }
</style>