<template>
    <div>
        <Header></Header>
        <div @click="$router.go(-1)" class="text-center list-button">
            <button class="btn btn-sm btn-primary">Atras</button>
        </div>
        <div class="container">
            <h4>Ciclo: <strong>{{ nombreCiclo }}</strong></h4>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <label>F. Inicio:</label>
                    <input v-model="formReporte.fecha_ini" type="date" class="form-control"/>
                </div>
                <div class="col-md-3">
                    <label>F. Fin:</label>
                    <input v-model="formReporte.fecha_fin" type="date" class="form-control"/>
                </div>
                <div class="col-md-3">
                    <label>Curso:</label>
                    <select v-model="formReporte.codigoCurso" class="form-control">
                        <option v-for="(item,index) in listaCursos" :key="index" :value="item.id_curso">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label class="label-space"></label>
                    <button @click="filtraReporte" class="btn btn-success">Filtrar</button>
                </div>
            </div>

            <br>
            
            <h4 @click="showAsistencia=!showAsistencia;" class="control-show">Asistencia: <strong>{{ showAsistencia ? "-" : "+" }}</strong></h4>
            <table v-if="showAsistencia" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Curso</th>
                        <th>Clase</th>
                        <th>Dia</th>
                        <th>Entrada</th>
                        <th>Salida</th>
                        <th>Tiempo</th>
                    </tr> 
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataAsistencia" :key="index">
                        <td>{{ item.curso }}</td>
                        <td>{{ item.hini+" - "+item.hfin }}</td>
                        <td>{{ item.dia }}</td>
                        <td>{{ item.hora_inicio }}</td>
                        <td>{{ item.hora_fin }}</td>
                        <td>{{ item.tiempo }}</td>
                    </tr>
                </tbody>
            </table>

            <h4 @click="showNotas=!showNotas;" class="control-show">Notas: <strong>{{ showNotas ? "-" : "+" }}</strong></h4>
            <table v-if="showNotas" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Simulacro</th>
                        <th>Horario</th>
                        <th>H. Inicia</th>
                        <th>H. Finaliza</th>
                        <th>M. Finaliza</th>
                        <th>Correctas</th>
                        <th>Incorrectas</th>
                        <th>No Marcadas</th>
                        <th>P. Base</th>
                        <th>Puntaje</th>
                        <th>Ponderado</th>
                        <th>Detalle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in dataNotas" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.dia+" ("+item.hora_ini+" - "+item.hora_fin+")" }}</td>
                        <td>{{ item.hora_inicia }}</td>
                        <td>{{ item.hora_finaliza }}</td>
                        <td>{{ item.modo_finaliza }}</td>
                        <td>{{ item.correctas }}</td>
                        <td>{{ item.incorrectas }}</td>
                        <td>{{ item.no_marcadas }}</td>
                        <td>{{ item.puntaje_base }}</td>
                        <td>{{ item.puntaje }}</td>
                        <td>{{ item.ponderado }}</td>
                        <td class="text-center">
                            <router-link v-if="item.estado=='1'" :to="{name:'SimulacroDetalle', params:{ciclo:item.id_ciclo, simulacro: item.id_simulacro}}" :key="index" class="btn btn-sm btn-primary" tag="button">
                                <i class="bi bi-123"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    components: {
       Header
    },
    data(){
        return {
            nombreCiclo: '',
            dataAsistencia: [],
            dataNotas: [],
            listaCursos: [],
            formReporte: {
                action: 'getInfoAsistenciaNotas',
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: 'all',
                fecha_ini: '',
                fecha_fin: ''                
            },
            showAsistencia: true,
            showNotas: true,
        }
    },
    mounted(){
        this.$store.dispatch('ajaxCursos', {
            action: 'getInfoCiclo',
            codigoUni: this.$route.params.codigo,
            codigoCiclo: this.$route.params.ciclo,
        })
        .then(data=>{
            this.nombreCiclo = data.label_1;
            this.listaCursos = data.data;
        });
    },
    methods: {
        filtraReporte(){
            this.$store.dispatch('ajaxCursos', this.formReporte)
            .then(data=>{
                this.dataAsistencia = data.data.asistencia;
                this.dataNotas = data.data.simulacros;
            });
        }
    }
}
</script>

<style scoped>
    .list-button{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .label-space{
        width: 100%;
    }
    .control-show{
        cursor: pointer;
    }
</style>