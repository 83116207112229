<template>
   <div>
        <Header></Header>
        <div class="container">
            <h5 class="files-h5">Archivos: {{ cursoLabelCurso+" / "+cursoLabelTema }}</h5>
            <div v-if="false">
                <div class="col-sm-12">
                    <label class="form-file">Archivo:</label>
                    <input @change="onFileChange" class="form-control" type="file" id="fileUpload"/>
                </div>
                <div class="col-sm-12">
                    <label class="form-label">Nombre (Opcional):</label>
                    <input v-model="formFile.nombre" class="form-control" placeholder="Nombre (Opcional)"/>
                </div>
                <div v-if="formOkFile" class="alert alert-success" role="alert">
                    {{ msgOkFile }}
                </div>
                <div v-if="formErrorFile" class="alert alert-danger" role="alert">
                    {{ msgErrorFile }}
                </div>
                <div class="text-center listButton">
                    <button @click="saveFile" class="btn btn-sm btn-success">Guardar Archivo</button>
                </div>
            </div>

            <table class="table table-bordered table-files">
                <thead>
                    <tr>
                        <th>Archivo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in archivos" :key="index">
                        <td><a :href="$store.getters.urlApiFiles+'?id='+item.file+'&token='+$store.getters.getToken" target="_blank">{{ item.nombre }}</a></td>
                    </tr>
                </tbody>
            </table>
            <h5 class="files-h5">Clase Grabada:</h5>
            <div class="text-center">
                <div v-for="(item, index) in videos" :key="index">
                    <video oncontextmenu="return false;" v-if="item.type=='external_server'" width="640" height="360" controls controlsList="nodownload">
                        <source :src="item.url" type="video/mp4">
                    </video>
                    <video oncontextmenu="return false;" v-if="item.type=='api'" width="640" height="360" controls controlsList="nodownload">
                        <source :src="$store.getters.urlVideoFiles+item.file" type="video/mp4">
                    </video>
                    <div oncontextmenu="return false;" v-if="item.type=='google_drive'" style="width: 640px; height: 360px; position: relative; left: 50%; transform: translateX(-50%);">
                        <iframe allowfullscreen v-if="item.type=='google_drive'" width="640" height="360" :src="'https://drive.google.com/file/d/'+item.id+'/preview'" frameborder="0" scrolling="no" seamless=""></iframe>
                        <div style="width: 80px; height: 80px; position: absolute; opacity: 0; right: 0px; top: 0px;">&nbsp;</div>
                    </div>
                    <br>
                </div>
            </div>
            <div class="text-center">
                <router-link class="btn btn-sm btn-primary" :to="{name:'CursosTemas'}" tag="button">
                    Atras
                </router-link>
            </div>
            
        </div>
    </div> 
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            formFile:{
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoTema: this.$route.params.tema,
                codigoOrigen: this.$route.params.origen,
                action: 'getTemasArchivos',
                file: null,
                nombre: ''
            },
            formErrorFile: false,
            formOkFile: false,
            msgErrorFile: '',
            msgOkFile: '',
            archivos: [],
            videos: [],
            esProfesor: (this.$route.params.origen=='profesor'),
            cursoLabelCurso: '',
            cursoLabelTema: ''
        }
    },
    mounted(){
        this.$store.dispatch('ajaxCursos', this.formFile)
        .then(data=>{
            this.archivos = data.data;
            this.cursoLabelCurso = data.label_2;
            this.cursoLabelTema = data.label_3;
            this.videos = data.videos;
        });
    },
    methods:{
        onFileChange(e){
            this.formFile.file = e.target.files[0];
        },
        saveFile(){
            this.$store.dispatch('editAdminTemaFileUpload', this.formFile)
            .then( data=> {
                if( data.result=="1" ){
                    this.formOkFile = true;
                    this.msgOkFile = data.msg;
                    //reseteando y archivos
                    this.archivos = data.data;
                    document.getElementById("fileUpload").value = "";
                    this.formFile.nombre = "";
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }else{
                    this.formErrorFile = true;
                    this.msgErrorFile = data.error_msg;
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }
            });
        },
        removeFile( file ){
            this.formFileRemove.fileToken = file;
            this.$store.dispatch('editAdminTemaFileRemove', this.formFileRemove)
            .then( data=> {
                if( data.result=="1" ){
                    this.formOkFile = true;
                    this.msgOkFile = data.msg;
                    this.archivos = data.data;
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }else{
                    this.formErrorFile = true;
                    this.msgErrorFile = data.error_msg;
                    setTimeout(()=>{ this.formOkFile=false; this.formErrorFile=false; }, 2000);
                }
            });
        }
    }
}
</script>
<style scoped>
    .files-h5{
        margin-top: 10px;
    }
    .listButton{
        margin-top: 10px;
    }
    .table-files{
        margin-top: 10px;
    }
</style>