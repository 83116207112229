<template>
    <div>
        <Header></Header>
        <div class="container">
            <div @click="$router.go(-1)" class="text-center" style="margin: 10px;">
                <button class="btn btn-sm btn-primary">Atras</button>
            </div>
            <h3>Detalle Simulacro: {{ this.labelOriginal }}</h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>T. Restante</th>
                        <th>P. Promedio</th>
                        <th>N. Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ tiempoRestante }}</td>
                        <td>{{ promedioPuntaje }}</td>
                        <td>{{ promedioNota }}</td>                        
                    </tr>
                </tbody>
            </table>


 
                <h3>Gestión de Puntajes: 
                    <button @click=" showDataPreguntas=!showDataPreguntas " class="btn btn-sm btn-primary">
                        <i v-show="!showDataPreguntas" class="bi bi-plus-lg"></i>
                        <i v-show="showDataPreguntas" class="bi bi-dash"></i>
                    </button>
                </h3>
                <div v-show="showDataPreguntas">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>ORDEN</th>
                                <th>OPCIÓN CORRECTA</th>
                                <th>P. CORRECTO</th>
                                <th>P. INCORRECTO</th>
                                <th>P. VACÍO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in dataPreguntas" :key="index">
                                <td>{{ item.orden }}</td>
                                <td>
                                    <select v-model="item.opcion_correcta" class="form-control input-sm">
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="E">E</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="number" v-model="item.puntaje_correcto" class="form-control input-sm" placeholder="P. CORRECTO"/>
                                </td>
                                <td>
                                    <input type="number" v-model="item.puntaje_incorrecto" class="form-control input-sm" placeholder="P. INCORRECTO"/>
                                </td>
                                <td>
                                    <input type="number" v-model="item.puntaje_vacio" class="form-control input-sm" placeholder="P. VACÍO"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="formOk" class="alert alert-success" role="alert">
                        {{ msgOk }}
                    </div>
                    <div v-if="formError" class="alert alert-danger" role="alert">
                        {{ msgError }}
                    </div>

                    <div class="text-center">
                        <button @click="updateSimulacro" class="btn btn-sm btn-success">Guardar Puntajes</button>
                    </div>
                </div>
                



            <h4>Detalle:</h4>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>H. Inicio</th>
                        <th>H. Fin</th>
                        <th>M. Fin</th>
                        <th>Correctas</th>
                        <th>Incorrectas</th>
                        <th>No Marcadas</th>
                        <th>Puntaje</th>
                        <th>Ponderado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in data" :key="index">
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.hora_inicia }}</td>
                        <td>{{ item.hora_finaliza }}</td>
                        <td>{{ item.modo_finaliza }}</td>
                        <td>{{ item.correctas }}</td>
                        <td>{{ item.incorrectas }}</td>
                        <td>{{ item.no_marcadas }}</td>
                        <td>{{ item.puntaje }}</td>
                        <td>{{ item.ponderado }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return{
            promedioPuntaje: 0,
            promedioNota: 0,
            tiempoRestante: 0,
            labelOriginal: '',
            data: [],
            dataPreguntas: [],
            showDataPreguntas: false,

            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.$route.params.codigo,
            codigoCiclo: this.$route.params.ciclo,
            codigoSimulacro: this.$route.params.simulacro,
            action: 'getSimulacroDetalle'
        })
        .then( data=> {
            this.labelOriginal = data.label_simulacro;
            this.data = data.data;
            this.promedioPuntaje = data.promedio_puntaje;
            this.promedioNota = data.promedio_nota;
            this.tiempoRestante = data.tiempo_restante;
            this.dataPreguntas = data.data_preguntas;
        });
    },
    methods: {
        updateSimulacro(){
            if( !confirm("Se procederá a ACTUALIZAR el SIMULACRO, desea continuar?") ){
                return false;
            }

            this.$store.dispatch('ajaxAdminCursos', {
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoSimulacro: this.$route.params.simulacro,
                action: 'updateSimulacroDetalle',
                dataPreguntas: this.dataPreguntas
            })
            .then( data=> {
                this.$helpers.executeMessage( this, data );
            });
        }
    }
}
</script>

<style scoped>

</style>