<template>
    <div>
        <Header></Header>
        <div class="container">
            <h4>Detalle Curso: {{ this.labelOriginal }}</h4>
            <div class="col-sm-12">
                <label class="form-label">Nombre:</label>
                <input v-model="form.label" class="form-control" placeholder="Nombre">
            </div>
            <label class="form-label label-horarios">
                Horarios:
                <button @click="addHorario" class="btn btn-sm btn-primary">
                    <i class="bi bi-plus-circle"></i>
                </button>
            </label>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Dia</th>
                        <th>H. Inicio</th>
                        <th>H. fin</th>
                        <th>Crear Clase</th>
                        <th>Quitar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" ( item, index ) in form.horarios" :key="index">
                        <td>
                            <select v-model="item.dia" class="form-control input-sm">
                                <option value="1">LUNES</option>
                                <option value="2">MARTES</option>
                                <option value="3">MIERCOLES</option>
                                <option value="4">JUEVES</option>
                                <option value="5">VIERNES</option>
                                <option value="6">SABADO</option>
                                <option value="0">DOMINGO</option>
                            </select>
                        </td>
                        <td><input type="time" v-model="item.hini" class="form-control input-sm"/></td>
                        <td><input type="time" v-model="item.hfin" class="form-control input-sm"/></td>
                        <td class="text-center">
                            <button @click="crearClase(index)" class="btn btn-sm btn-success">
                                Crear Clase
                            </button>
                        </td>
                        <td class="text-center">
                            <button @click="removeHorario(index)" class="btn btn-sm btn-danger">
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
            <div class="text-center col-xs-12 listButton">
                <button @click="saveCurso" class="btn btn-success">Guardar</button> 
                <router-link :to="{name:'AdminCursos', params:{codigo: this.form.codigoUni, ciclo:this.form.codigoCiclo}}" class="btn btn-primary btnAtras" tag="button">
                    Atras
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    computed:{
        nuevoCurso(){
            return (this.$route.params.curso==='new');
        }
    },
    data(){
        return{
            form:{
                label: '',
                horarios: [],
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                action: ( this.$route.params.curso==='new' ? 'newAdminCurso' : 'editAdminCurso')
            },
            labelOriginal: '',
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    mounted(){
        if( !this.nuevoCurso ){
            this.$store.dispatch('ajaxAdminCursos', {
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                action: 'getCursoDetalle'
            })
            .then( data=> {
                this.form.label = data.data.label;
                this.form.horarios = data.data.horarios;
                this.labelOriginal = data.data.label;
            });
        }else{
            this.labelOriginal='Nuevo Curso';
        }
    },
    methods:{
        saveCurso(){
            this.$store.dispatch('ajaxAdminCursos', this.form)
            .then( data=> {
                this.$helpers.executeMessage( this, data );
            });
        },
        addHorario(){
            this.form.horarios.push({dia:'0', hini:'', hfin:''});
        },
        removeHorario(index){
            this.form.horarios.splice(index, 1);
        },
        crearClase( index ){
            let id_horario = this.form.horarios[index].id_horario;
            this.$store.dispatch('ajaxAdminCursos', {
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo,
                codigoCurso: this.$route.params.curso,
                codigoHorario: id_horario,
                action: 'createNewClase'
            })
            .then( data=> {
                this.$helpers.executeMessage( this, data );
            });
        }
    }
}
</script>
<style scoped>
    .btnAtras{
       margin-left: 4px;
    }
    .listButton{
        margin-top: 10px;
    }
    .label-horarios{
        margin-top: 10px;
    }
    @import url("~bootstrap-icons\\font\\bootstrap-icons.css");
</style>