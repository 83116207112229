<template>
    <div>
        <div class="container">
            <div class="col-md-12">
                <input v-model="key" @keyup.enter="getInfo" class="form-control" placeholder="Clave" type="text"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoad: false,
            key: ''
        }
    },
    methods: {
        getInfo() {
            this.$store.dispatch('ajaxSystem', {
                key: this.key
            })
            .then(data=>{
                console.log(data);
            });
        }
    }
}
</script>

<style scoped>
    .container{
        margin-top: 10px;
    }
</style>