<template>
    <div>
        <Header></Header>
        <div class="container">
            <br>
            <div class="text-center">
                <button @click="$router.go(-1)" class="btn btn-primary">Atras</button>
            </div>
            <br>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>ORDEN</th>
                        <th>PREGUNTA</th>
                        <th>RESPUESTA</th>
                        <th>MARCADO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataPreguntas" :key="index">
                        <td>{{item.orden}}</td>
                        <td>
                            <img class="imgPreg" :src="$store.getters.urlApiImagenes+item.imagen">
                        </td>
                        <td :class=" (item.opcion_correcta==item.marcado ? 'success' : ( item.marcado=='NO' ? '' : 'danger' ) ) ">{{item.opcion_correcta}}</td>
                        <td>{{ (item.marcado=='NO' ? '' : item.marcado) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    components: {
       Header
    },
    data(){
        return {
            dataPreguntas: []
        }
    },
    mounted(){
        this.$store.dispatch('ajaxCursos', {
            action: 'getInfoSimulacro',
            codigoCiclo: this.$route.params.ciclo,
            codigoSimulacro: this.$route.params.simulacro
        })
        .then(data=>{
            this.dataPreguntas = data.data.preguntas;
        });
    },
}
</script>

<style scoped>
    .imgPreg{
        width: 500px;
    }
    .success{
        background-color: #A1FCA4;
    }
    .danger{
        background-color: #FA9D9D;
    }
</style>