<template>
    <div>
        <Header></Header>
        <div @click="$router.go(-1)" class="text-center list-button">
            <button class="btn btn-primary">Atras</button>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <label>Ciclo:</label>
                    <select @change="resetAll" v-model="formFiltro.ciclo" class="form-control">
                        <option v-for="(item,index) in ciclos" :key="index" :value="item.id_ciclo">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label>Curso:</label>
                    <select v-model="formFiltro.curso" class="form-control">
                        <option v-for="(item,index) in listaCursos" :key="index" :value="item.id_curso">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label>Usuario:</label>
                    <select v-model="formFiltro.alumno" class="form-control">
                        <option v-for="(item,index) in listaAlumnos" :key="index" :value="item.login">
                            {{ item.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label>F. Inicio:</label>
                    <input v-model="formFiltro.fecha_ini" type="date" class="form-control"/>
                </div>
                <div class="col-md-4">
                    <label>F. Fin:</label>
                    <input v-model="formFiltro.fecha_fin" type="date" class="form-control"/>
                </div>
                <div class="col-md-4">
                    <br>
                    <button @click="filterReport" class="btn btn-success">Filtrar</button>
                </div>
            </div>
            <br>

            <h5>Asistencias y Entradas:</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Hora</th>
                        <th>Tipo</th>
                        <th>Ciclo</th>
                        <th>Curso</th>
                        <th>Clase</th>
                        <th>Alumno</th>
                        <th>Detalle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataTable" :key="index" :class="item.tipo=='CLASE' ? 'trClase' : ''">
                        <td>{{item.hora}}</td>
                        <td>{{item.tipo}}</td>
                        <td>{{item.ciclo}}</td>
                        <td>{{item.curso}}</td>
                        <td>{{item.clase}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.detalle}}</td>
                    </tr>
                </tbody>
            </table>

            <h5>Notas:</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Ciclo</th>
                        <th>Alumno</th>
                        <th>Simulacro</th>
                        <th>H. Inicio</th>
                        <th>H. Fin</th>
                        <th>M. Fin</th>
                        <th>Correctas</th>
                        <th>Incorrectas</th>
                        <th>No Marcadas</th>
                        <th>Puntaje</th>
                        <th>Ponderado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataNotas" :key="index">
                        <td>{{item.ciclo}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.simulacro}}</td>
                        <td>{{item.hora_inicia}}</td>
                        <td>{{item.hora_finaliza}}</td>
                        <td>{{item.modo_finaliza}}</td>
                        <td>{{item.correctas}}</td>
                        <td>{{item.incorrectas}}</td>
                        <td>{{item.no_marcadas}}</td>
                        <td>{{item.puntaje}}</td>
                        <td>{{item.ponderado}}</td>
                    </tr>
                </tbody>
            </table>


        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
    components: { Header },
    data(){
        return {
            ciclos: [],
            cursos: {},
            alumnos: {},
            formFiltro: {
                action: 'getReporteUsuarios',
                ciclo: 'all',
                curso: 'all',
                alumno: 'all',
                fecha_ini: '',
                fecha_fin: ''
            },
            dataTable: [],
            dataNotas: []
        }
    },
    mounted(){
        this.$store.dispatch('ajaxAdminUsuarios', {action: 'getDataReporteUsuarios'})
        .then( data =>{
            this.ciclos = data.data.ciclos;
            this.cursos = data.data.cursos;
            this.alumnos = data.data.alumnos;
        });
    },
    computed: {
        listaCursos(){
            return this.cursos[this.formFiltro.ciclo];
        },
        listaAlumnos(){
            return this.alumnos[this.formFiltro.ciclo];
        }
    },
    methods: {
        resetAll(){
            this.formFiltro.curso='all';
            this.formFiltro.alumno='all';
        },
        filterReport(){
            this.$store.dispatch('ajaxAdminUsuarios', this.formFiltro)
            .then( data =>{
                this.dataTable = data.data.asistencia;
                this.dataNotas = data.data.notas;
            });
        }
    }
}
</script>

<style scoped>
    .list-button{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .trClase{
        background-color: rgb(243, 206, 191);
    }
</style>