<template>
    <div>
        <Header></Header>
        <div @click="$router.go(-1)" class="text-center list-button">
            <button class="btn btn-sm btn-primary">Atras</button>
        </div>
        <div class="container">
            <h4>Nuevo Simulacro: <label class="bold"><strong>{{ nombreCiclo }}</strong></label></h4>
            <div class="row">
                <div class="col-md-4">
                    <label>Nombre Simulacro:</label>
                    <input v-model="formSimulacro.nombre" class="form-control input-sm" type="text" placeholder="Nombre Simulacro"/>
                </div>
                <div class="col-md-4">
                    <label>Numero de Preguntas:</label>
                    <input v-model="formSimulacro.preguntas" class="form-control input-sm" type="number" placeholder="Numero de Preguntas"/>
                </div>
                <div class="col-md-4">
                    <label>Archivo:</label>
                    <input accept=".zip,.ZIP" type="file" class="form-control input-sm" @change="onFileChange">
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-4">
                    <label>Dia:</label>
                    <input v-model="formSimulacro.dia" class="form-control input-sm" type="date" placeholder="Dia"/>
                </div>
                <div class="col-md-4">
                    <label>H. Inicio:</label>
                    <input v-model="formSimulacro.hora_ini" class="form-control input-sm" type="time" placeholder="H. Inicio"/>
                </div>
                <div class="col-md-4">
                    <label>H. Fin:</label>
                    <input v-model="formSimulacro.hora_fin" class="form-control input-sm" type="time" placeholder="H. Fin"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label>P. Correcto:</label>
                    <input v-model="formSimulacro.puntaje_favor" type="number" class="form-control input-sm" placeholder="P. Correcto">
                </div>
                <div class="col-md-4">
                    <label>P. Incorrecto:</label>
                    <input v-model="formSimulacro.puntaje_contra" type="number" class="form-control input-sm" placeholder="P. Incorrecto">
                </div>
                <div class="col-md-4">
                    <label>P. No Marcado:</label>
                    <input v-model="formSimulacro.puntaje_nulo" type="number" class="form-control input-sm" placeholder="P. No Marcado">
                </div>
            </div>
            
            <div class="text-center list-button">
                <button @click="saveNewSimulacro" :disabled="!isValidForm" class="btn btn-sm btn-success">Guardar</button>
            </div>

            <div v-if="formOk" class="alert alert-success" role="alert">
                {{ msgOk }}
            </div>
            <div v-if="formError" class="alert alert-danger" role="alert">
                {{ msgError }}
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
    data(){
        return{
            formSimulacro:{
                action: 'newSimulacro',
                nombre: '',
                preguntas: '',
                dia: '',
                hora_ini: '',
                hora_fin: '',
                puntaje_favor: '0',
                puntaje_contra: '0',
                puntaje_nulo: '0',
                file: '',
                codigoUni: this.$route.params.codigo,
                codigoCiclo: this.$route.params.ciclo
            },
            nombreCiclo: '',
            formError: false,
            formOk: false,
            msgError: '',
            msgOk: ''
        }
    },
    components: { Header },
    mounted(){
        this.$store.dispatch('ajaxAdminCursos', {
            codigoUni: this.$route.params.codigo,
            codigoCiclo: this.$route.params.ciclo,
            action: 'getInfoNuevoSimulacro'
        })
        .then( data=> {
            this.nombreCiclo = data.label_1;
        });
    },
    computed:{
        isValidForm(){
            return true;
        }
    },
    methods: {
        onFileChange(e){
            this.formSimulacro.file = e.target.files[0];
        },
        saveNewSimulacro(){
            if( !confirm("Se procedera a crear el simulacro, todos los datos son correctos?") )
                return false;
            this.$store.dispatch('ajaxAdminCursos', this.formSimulacro)
            .then( data=> {
                this.$helpers.executeMessage(this, data, 6000, 6000);
            });
            
        }
    }
}
</script>

<style scoped>
    .list-button{
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>