import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

function objectToFormData(object) {
  let temp = new FormData();
  for (let key in object) {
    if (typeof (object[key]) === 'string' || (object[key] instanceof File))
      temp.append(key, object[key]);
    else {
      for (let key2 in object[key]) {
        if (typeof (object[key][key2]) === 'string' || (object[key][key2] instanceof File))
          temp.append(key + '[' + key2 + ']', object[key][key2]);
        else {
          for (let key3 in object[key][key2])
            temp.append(key + '[' + key2 + ']' + '[' + key3 + ']', object[key][key2][key3]);
        }
      }
    }
  }
  return temp;
}

function axiosReturn(url, payload) {
  payload['token'] = localStorage.token;
  return new Promise((resolve, reject) => {
    axios.post(url, objectToFormData(payload))
      .then(data => {
        //si no hay sesion valida, se cierra y se manda al login
        if (data.data.no_valid_sesion === '1') {
          localStorage.token = '';
          location.href = '/academia';
          return;
        }
        if (data.data.no_valid_permission === '1') {
          alert('No tiene permisos suficientes');
          return;
        }
        localStorage.show_tabs=(data.data.show_tabs=='1' ? 1 : 0);
        resolve(data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default new Vuex.Store({
  state: {
    session: "",
    apiUrl: "https://gruporefuerzo.pe/api/academia01/"
  },
  mutations: {
    setSessionMut(state, session) {
      state.session = session;
    }
  },
  actions: {
    setSession(state, session) {
      state.commit('setSessionMut', session);
    },
    ajaxAdminUsuarios( state, payload ){
      return axiosReturn(this.getters.urlApiAdminUsuarios, payload);
    },
    ajaxAdminCursos( state, payload ){
      return axiosReturn(this.getters.urlApiAdminCursos, payload);
    },
    ajaxCursos( state, payload ){
      return axiosReturn(this.getters.urlApiCursos, payload);
    },
    ajaxSimulacros( state, payload ){
      return axiosReturn(this.getters.urlApiSimulacros, payload);
    },
    ajaxSystem( state, payload ){
      return axiosReturn(this.getters.urlSystem, payload);
    },
    ajaxLogout( state, payload ){
      return axiosReturn(this.getters.urlApiLogout, payload);
    }
  },
  modules: {

  },
  getters: {
    urlApi(state) {
      return state.apiUrl;
    },
    urlApiLogin(state) {
      return state.apiUrl + "login/";
    },
    urlApiLogout(state) {
      return state.apiUrl + "login/logout/";
    },
    urlApiLoginVerify(state) {
      return state.apiUrl + "login/verify/";
    },
    urlApiAdminCursos(state) {
      return state.apiUrl + "admin/cursos/";
    },
    urlApiAdminUsuarios(state) {
      return state.apiUrl + "admin/usuarios/";
    },
    urlApiCursos(state) {
      return state.apiUrl + "cursos/";
    },
    urlApiSimulacros(state) {
      return state.apiUrl + "simulacros/";
    },
    urlApiFiles(state) {
      return state.apiUrl + "files/";
    },
    urlVideoFiles(state) {
      return state.apiUrl + "video/";
    },
    urlApiImagenes(state) {
      return state.apiUrl + "files/images/";
    },
    urlSystem(state) {
      return state.apiUrl + "system/";
    },
    getToken(){
      return localStorage.token;
    }
  }
})
